import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslationsService} from '@core/http/translations.service';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html'
})
export class SuggestionComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() text: string;
  @Input() sourceLanguage: string;
  @Input() targetLanguage: string;
  @Input() expanded: boolean;
  @Input() change: EventEmitter<string>;
  @Input() disableSelect: boolean;
  @Input() showCopyButtons = true;
  @Output() onSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCopyToGeneric: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCopyToSeeds: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCopyToCuttings: EventEmitter<string> = new EventEmitter<string>();

  public result: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private translationsService: TranslationsService
  ) {
  }

  ngOnInit(): void {
    if (!this.text) {
      this.result = '';
    }

    if (this.change) {
      const subscription = this.change
        .pipe(
          debounceTime(1000)
        )
        .subscribe((text: string) => {
          this.text = text;
          this.findSuggestion();
        });

      this.subscriptions.push(subscription);
    }

    this.findSuggestion();
  }

  findSuggestion(): void {
    if (!this.text) {
      return;
    }

    const subscription = this.translationsService.suggest(this.text, this.targetLanguage, this.sourceLanguage)
      .subscribe(result => {
        this.result = result;
      });

    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
