import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '@core/services/authentication.service';
import {PrimeNGConfig} from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(private translate: TranslateService,
              private authService: AuthenticationService,
              private config: PrimeNGConfig) {
    this.initLayout();
    this.initAuth();
    this.initLanguages();
  }

  private initAuth(): void {
    this.authService.initiateSequence();
  }

  private initLanguages(): void {
    this.translate.addLangs(['nl', 'en']);
    this.translate.setDefaultLang('nl');
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(this.translate.getBrowserLang().match(/nl|fr|en|de/) ? browserLang : 'nl');
  }

  private initLayout() {

  }
}
