export class BasicPlant {
  constructor(
    public id: number,
    public plantCode: string,
    public fullnameEn: string
  ) {}

  static adapt(item: any): BasicPlant {
    return new BasicPlant(
      item.id,
      item.plantCode,
      item.fullnameEn
    );
  }
}
