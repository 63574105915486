export const translationRoutesNames = {
  DASHBOARD: '',
  PLANT_OVERVIEW: 'plants',
  PLANT_EDIT: 'plants/:id/edit',
  PRODUCT_OVERVIEW: 'products',
  PRODUCT_EDIT: 'products/:id/edit',
  SELECTEDCULTIVAR_OVERVIEW: 'selectedcultivars',
  SELECTEDCULTIVAR_EDIT: 'selectedcultivars/:id/edit',
  SPECIE_OVERVIEW: 'species',
  SPECIE_EDIT: 'species/:id/edit',
  GENUS_OVERVIEW: 'geni',
  GENUS_EDIT: 'geni/:id/edit',
};
