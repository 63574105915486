import {Directive, Input, Optional, Self} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';
import {InputType} from '@shared/components/input/input-type.enum';

@Directive()
export abstract class AbstractControlDirective implements ControlValueAccessor {
  @Input() label: string;
  @Input() maxRange: number;
  @Input() formControlName: string;
  @Input() orientation: InputType = InputType.VERTICAL;
  onChange: (value: any) => void;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  get control(): AbstractControl {
    return this.ngControl.control;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  isVertical(): boolean {
    return this.orientation === InputType.VERTICAL;
  }

  writeValue(obj: any): void {
  }
}
