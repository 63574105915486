import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingIndicatorComponent} from './layout/loading-indicator/loading-indicator.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {InputComponent} from './components/input/input.component';
import {TextComponent} from './components/text/text.component';
import {TitlesComponent} from './components/titles/titles.component';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {TextAreaComponent} from './components/text-area/text-area.component';
import {ListboxComponent} from './components/listbox/listbox.component';
import {MultiSelectComponent} from './components/multi-select/multi-select.component';
import {TemperatureSliderComponent} from './components/temperature-slider/temperature-slider.component';
import {AuditComponent} from './components/audit/audit.component';
import {CalendarComponent} from './components/calendar/calendar.component';
import {OutputComponent} from './components/output/output.component';
import {TaxonomyTranslationsComponent} from './components/taxonomy-translations/taxonomy-translations.component';
import {GenusSpeciePeriodsComponent} from './components/genus-specie-periods/genus-specie-periods.component';
import {GenusSpeciePropertiesTabComponent} from '@shared/components/genus-specie-properties-tab/genus-specie-properties-tab.component';
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {ListboxModule} from 'primeng/listbox';
import {AccordionModule} from 'primeng/accordion';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {InputMaskModule} from 'primeng/inputmask';
import {InputTextModule} from 'primeng/inputtext';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {CalendarModule} from 'primeng/calendar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FileUploadModule} from 'primeng/fileupload';
import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {TabViewModule} from 'primeng/tabview';
import {ToolbarModule} from 'primeng/toolbar';
import {SplitAndGetPipe} from './pipes/split-and-get.pipe';
import {FileSizePipe} from './pipes/filesize.pipe';
import {TruncatetextPipe} from './pipes/truncatetext.pipe';
import { CardComponent } from './components/card/card.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { GenusIconsComponent } from './components/genus-icons/genus-icons.component';
import { ScIconsComponent } from './components/sc-icons/sc-icons.component';
import {ErrorComponent} from '@shared/components/error/error.component';
import { ProductIconComponent } from './components/product-icon/product-icon.component';
import { ProductIconListComponent } from './components/product-icon-list/product-icon-list.component';
import { ProductPotsizeIconComponent } from './components/product-potsize-icon/product-potsize-icon.component';
import { ProductHeightIconComponent } from './components/product-height-icon/product-height-icon.component';
import { SizeSliderComponent } from './components/size-slider/size-slider.component';
import {SuggestionComponent} from '@shared/components/suggestion/suggestion.component';
import {RippleModule} from 'primeng/ripple';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import {InputNumberModule} from 'primeng/inputnumber';
import {FloatLabelModule} from 'primeng/floatlabel';
import {InputGroupModule} from 'primeng/inputgroup';
import {InputGroupAddonModule} from 'primeng/inputgroupaddon';
import {CardModule} from 'primeng/card';

@NgModule({
    declarations: [
        SuggestionComponent,
        ErrorComponent,
        LoadingIndicatorComponent,
        InputComponent,
        TextComponent,
        TitlesComponent,
        DropdownComponent,
        TextAreaComponent,
        ListboxComponent,
        MultiSelectComponent,
        TemperatureSliderComponent,
        SizeSliderComponent,
        AuditComponent,
        CalendarComponent,
        OutputComponent,
        TaxonomyTranslationsComponent,
        GenusSpeciePeriodsComponent,
        GenusSpeciePropertiesTabComponent,
        CheckboxComponent,
        SplitAndGetPipe,
        FileSizePipe,
        TruncatetextPipe,
        CardComponent,
        OrderByPipe,
        GenusIconsComponent,
        ScIconsComponent,
        ProductIconComponent,
        ProductIconListComponent,
        ProductPotsizeIconComponent,
        ProductHeightIconComponent,
        SizeSliderComponent,
        CustomDatePipe
    ],
  exports: [
    ButtonModule,
    CalendarModule,
    CardComponent,
    CheckboxModule,
    CommonModule,
    DropdownModule,
    FileUploadModule,
    InputMaskModule,
    InputTextModule,
    OverlayModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RouterModule,
    TableModule,
    TabViewModule,
    ToastModule,
    TranslateModule,
    TextComponent,
    TextAreaComponent,
    TitlesComponent,
    DropdownComponent,
    ListboxComponent,
    MultiSelectComponent,
    TemperatureSliderComponent,
    SizeSliderComponent,
    AuditComponent,
    CalendarComponent,
    GenusSpeciePeriodsComponent,
    GenusSpeciePropertiesTabComponent,
    TaxonomyTranslationsComponent,
    CheckboxComponent,
    OutputComponent,
    SplitAndGetPipe,
    FileSizePipe,
    TruncatetextPipe,
    OrderByPipe,
    GenusIconsComponent,
    ScIconsComponent,
    ProductIconListComponent,
    ProductHeightIconComponent,
    ProductPotsizeIconComponent,
    SuggestionComponent
  ],
  imports: [
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    CommonModule,
    DropdownModule,
    FileUploadModule,
    InputMaskModule,
    InputTextModule,
    OverlayModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RouterModule,
    ToastModule,
    TranslateModule,
    ListboxModule,
    MultiSelectModule,
    SliderModule,
    AccordionModule,
    FormsModule,
    InputTextareaModule,
    ToolbarModule,
    RippleModule,
    NgOptimizedImage,
    InputNumberModule,
    FloatLabelModule,
    InputGroupModule,
    InputGroupAddonModule,
    CardModule
  ],
})
export class SharedModule {
}
