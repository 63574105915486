<div class="exception-body notfound">
  <div class="exception-content">
    <div class="flower">
      <img src="assets/layout/images/sunflower.png" alt="mirage-layout" style="position:absolute;bottom: 0; left:0;height: 40%"/>
    </div>
    <div class="moon">
      <img src="assets/layout/images/pages/asset-moon.svg" alt="mirage-layout" />
    </div>
    <div class="exception-panel">
      <div class="exception-panel-content">
        <span class="tag">{{ errorCode }}</span>
        <h1>{{ errorTitle }}</h1>
        <div class="seperator"></div>
        <p>{{ errorDescription }}</p>
        <button pButton type="button" [routerLink]="['/']" label="Go To DashBoard"></button>
      </div>
    </div>
    <div class="desert"></div>
  </div>
</div>
