import {Component, Input} from '@angular/core';
import {AbstractControlDirective} from "@shared/directives/abstract-control.directive";

@Component({
  selector: 'app-listbox',
  templateUrl: './listbox.component.html',
  styleUrls: ['./listbox.component.css']
})
export class ListboxComponent extends AbstractControlDirective {

  @Input('options') _options: any[];
  @Input() placeholder: string = null;
  @Input() optionLabel: (option: any) => string = this.defaultLabel;
  @Input() optionValue: (option: any) => any = this.defaultValue;

  get options() {
    if (this.optionLabel) {
      this._options.map(option => {
        option.label = this.optionLabel(option);
        option.value = this.optionValue(option);
        return option;
      });
    }

    return this._options;
  }

  defaultLabel(option: any): string {
    return option?.name;
  }

  defaultValue(option: any): any {
    return option?.id;
  }

}
