import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'div[app-product-height-icon]',
  templateUrl: './product-height-icon.component.html',
  styleUrls: ['./product-height-icon.component.css']
})
export class ProductHeightIconComponent implements OnInit {

  @Input() fromHeight: number;
  @Input() tillHeight: number;

  constructor() { }

  ngOnInit(): void {
  }

  getHeight(): string {
    if (this.tillHeight == null || this.tillHeight == 0) {
      return this.fromHeight + ' cm';
    } else {
      return this.fromHeight + ' - ' + this.tillHeight + ' cm';
    }
  }
}
