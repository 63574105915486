import {Component, Input} from '@angular/core';
import {AbstractControlDirective} from "@shared/directives/abstract-control.directive";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent extends AbstractControlDirective {
  @Input() selectionMode = 'single';
  @Input() dateFormat = 'dd/mm/yy';
  @Input() readonlyInput = false;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() yearNavigator = false;
  @Input() monthNavigator = false;
  @Input() numberOfMonths = 1;
  @Input() view = 'date';
  @Input() yearRange: string = null;
  @Input() tooltipValue: string;

}
