
export class DateParser {

  static parseDate(value: string): Date | null {
    if (!value || typeof value !== 'string' || value.trim() === '') {
      return null;
    }

    const [day, month, yearHourMin] = value.split('/');
    const [year, hourMin] = yearHourMin.split(' ');
    const [hour, min] = hourMin.split(':');

    return new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(min));
  }
}
