  <div class="col-12" [formGroup]="form">
    <div class="grid">
      <app-calendar class="col-12  md:col-6"
                    [label]="'taxonomy.genus.properties.fromSewingPeriod' | translate"
                    formControlName="fromSewingPeriod"
      ></app-calendar>
      <app-calendar class="col-12  md:col-6"
                    [label]="'taxonomy.genus.properties.tillSewingPeriod' | translate"
                    formControlName="tillSewingPeriod"
      ></app-calendar>
      <app-calendar class="col-12  md:col-6"
                    [label]="'taxonomy.genus.properties.fromSewingSecondPeriod' | translate"
                    formControlName="fromSewingSecondPeriod"
      ></app-calendar>
      <app-calendar class="col-12  md:col-6"
                    [label]="'taxonomy.genus.properties.tillSewingSecondPeriod' | translate"
                    formControlName="tillSewingSecondPeriod"
      ></app-calendar>
      <app-calendar class="col-12  md:col-6"
                    [label]="'taxonomy.genus.properties.fromPottingPeriod' | translate"
                    formControlName="fromPottingPeriod"
      ></app-calendar>
      <app-calendar class="col-12  md:col-6"
                    [label]="'taxonomy.genus.properties.tillPottingPeriod' | translate"
                    formControlName="tillPottingPeriod"
      ></app-calendar>
      <app-calendar class="col-12  md:col-6"
                    [label]="'taxonomy.genus.properties.fromPottingSecondPeriod' | translate"
                    formControlName="fromPottingSecondPeriod"
      ></app-calendar>
      <app-calendar class="col-12  md:col-6"
                    [label]="'taxonomy.genus.properties.tillPottingSecondPeriod' | translate"
                    formControlName="tillPottingSecondPeriod"
      ></app-calendar>
      <app-calendar class="col-12  md:col-6"
                    [label]="'taxonomy.genus.properties.fromSalesPeriod' | translate"
                    formControlName="fromSalesPeriod"
      ></app-calendar>
      <app-calendar class="col-12  md:col-6"
                    [label]="'taxonomy.genus.properties.tillSalesPeriod' | translate"
                    formControlName="tillSalesPeriod"
      ></app-calendar>
      <app-calendar class="col-12  md:col-6"
                    [label]="'taxonomy.genus.properties.fromSalesSecondPeriod' | translate"
                    formControlName="fromSalesSecondPeriod"
      ></app-calendar>
      <app-calendar class="col-12  md:col-6"
                    [label]="'taxonomy.genus.properties.tillSalesSecondPeriod' | translate"
                    formControlName="tillSalesSecondPeriod"
      ></app-calendar>
    </div>
  </div>
