import {Component} from '@angular/core';
import {Representative} from '@shared/model/representative';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {RepresentativeService} from '@core/http/representative.service';
import {LoadingIndicatorService} from '@core/services/loading-indicator.service';
import {FormUtils} from '@core/utils/form.utils';
import {BreadcrumbService} from '@core/services/breadcrumb.service';
import {appRoutesLinks} from '@app/app.routes.links';
import {FloatLabelModule} from 'primeng/floatlabel';
import {ButtonDirective} from 'primeng/button';
import {NgIf} from '@angular/common';
import {Ripple} from 'primeng/ripple';
import {SharedModule} from '@shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {flatMap} from 'rxjs/operators';
import {finalize, Observable} from 'rxjs';
import {Country, countryLabel} from '@shared/model/country';
import {Language} from '@shared/model/language';
import {DropdownModule} from 'primeng/dropdown';

@Component({
  selector: 'app-representative-edit',
  standalone: true,
  imports: [
    ButtonDirective,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    Ripple,
    SharedModule,
    TranslateModule,
    FloatLabelModule,
    DropdownModule
  ],
  templateUrl: './representative-edit.component.html'
})
export class RepresentativeEditComponent {
  public representative: Representative = new Representative();
  public representativeSubscription$: Observable<Representative>;

  public form: FormGroup;
  public countries: any[] = [];
  public countryEnum = Country;

  // Current language, you may need to inject a language service
  public currentLanguage: Language = Language.NL;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private representativeService: RepresentativeService,
    private formBuilder: FormBuilder,
    private loadingIndicatorService: LoadingIndicatorService,
    private formUtils: FormUtils,
    private breadcrumbService: BreadcrumbService) {
  }

  ngOnInit(): void {
    this.breadcrumbService.setItemss([
      {
        icon: 'pi pi-home',
        route: appRoutesLinks.HOME
      },
      {
        label: 'Vertegenwoordigers',
        route: appRoutesLinks.REPRESENTATIVE_OVERVIEW
      },
      {
        label: 'Vertegenwoordiger Aanpassen'
      }
    ]);

    // Initialize country dropdown options
    this.initCountries();

    this.representativeSubscription$ = this.route.paramMap.pipe(
      flatMap((params: ParamMap) => this.representativeService.show(Number(params.get('id'))))
    );

    this.representativeSubscription$.subscribe(result => {
      this.representative = result;
      this.initForm();
      this.loadingIndicatorService.hide();
    });
  }

  private initCountries() {
    // Create dropdown options from the Country enum
    this.countries = Object.values(Country).map(country => {
      return {
        value: country,
        label: countryLabel[this.currentLanguage][country]
      };
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      firstName: [this.representative.firstName],
      lastName: [this.representative.lastName],
      phone: [this.representative.phone],
      gsm: [this.representative.gsm],
      fax: [this.representative.fax],
      email: [this.representative.email],
      street: [this.representative.street],
      houseNumber: [this.representative.houseNumber],
      city: [this.representative.city],
      country: [this.representative.country],
      company: [this.representative.company],
      website: [this.representative.website],
    });
  }

  public submit() {
    this.loadingIndicatorService.show();
    const representative = this.form.getRawValue();

    this.representativeService.editRepresentative(this.representative.id, representative)
      .pipe(
        finalize(() => this.loadingIndicatorService.hide())
      )
      .subscribe({
        next: (result) => {
          this.formUtils.showSuccessMessage(
            'Vertegenwoordiger aangepast',
            'De vertegenwoordiger werd succesvol aangepast'
          );
          this.router.navigate([appRoutesLinks.REPRESENTATIVE_OVERVIEW], {
            state: {
              success: true,
              message: 'De vertegenwoordiger werd succesvol aangepast'
            }
          });
        },
        error: (error) => {
          this.formUtils.showErrorMessage(
            'Fout bij aanpassen',
            'Er is een fout opgetreden bij het aanpassen van de vertegenwoordiger'
          );
        }
      });
  }

  goToOverview() {
    this.router.navigate(appRoutesLinks.REPRESENTATIVE_OVERVIEW);
  }
}
