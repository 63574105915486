import {Component} from '@angular/core';
import {ButtonDirective} from 'primeng/button';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgIf} from '@angular/common';
import {Ripple} from 'primeng/ripple';
import {SharedModule} from '@shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {Representative} from '@shared/model/representative';
import {Router} from '@angular/router';
import {LoadingIndicatorService} from '@core/services/loading-indicator.service';
import {FormUtils} from '@core/utils/form.utils';
import {BreadcrumbService} from '@core/services/breadcrumb.service';
import {appRoutesLinks} from '@app/app.routes.links';
import {RepresentativeService} from '@core/http/representative.service';
import {FloatLabelModule} from 'primeng/floatlabel';

@Component({
  selector: 'app-representative-create',
  standalone: true,
  imports: [
    ButtonDirective,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    Ripple,
    SharedModule,
    TranslateModule,
    FloatLabelModule
  ],
  templateUrl: './representative-create.component.html'
})
export class RepresentativeCreateComponent {

  public representative: Representative = new Representative();
  public form: FormGroup;


  constructor(
    private router: Router,
    private representativeService: RepresentativeService,
    private formBuilder: FormBuilder,
    private loadingIndicatorService: LoadingIndicatorService,
    private formUtils: FormUtils,
    private breadcrumbService: BreadcrumbService) {
  }

  ngOnInit(): void {
    this.breadcrumbService.setItemss([
      {
        icon: 'pi pi-home',
        route: appRoutesLinks.HOME
      },
      {
        label: 'Vertegenwoordigers',
        route: appRoutesLinks.REPRESENTATIVE_OVERVIEW
      },
      {
        label: 'Vertegenwoordiger Aanmaken'
      }
    ]);

    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      firstName: [this.representative.firstName],
      lastName: [this.representative.lastName],
      phone: [this.representative.phone, [Validators.required]],
      gsm: [this.representative.gsm, [Validators.required]],
      fax: [this.representative.fax],
      email: [this.representative.email, [Validators.required]],
      street: [this.representative.street],
      houseNumber: [this.representative.houseNumber],
      city: [this.representative.city],
      country: [this.representative.country],
      company: [this.representative.company],
      website: [this.representative.website],
    });
  }

  public submit() {
    if (this.form.valid) {
      this.loadingIndicatorService.show();
      const representative = this.form.getRawValue();

      this.representativeService.create(representative).subscribe(result => {
        this.router.navigate(appRoutesLinks.REPRESENTATIVE_OVERVIEW);
        this.loadingIndicatorService.hide();
        this.formUtils.showSuccessMessage('Vertegenwoordiger aangemaakt', 'We konden succesvol de vertegenwoordiger aanmaken');
      });
    } else {
      this.formUtils.validateAllFormFields(this.form);

    }
  }

  goToOverview() {
    this.router.navigate(appRoutesLinks.USER_OVERVIEW);
  }
}
