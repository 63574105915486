<app-input
          [label]="label"
          [control]="control"
>
  <input [class.col-8]="!isVertical()"
         type="text"
         [formControl]="control"
         pInputText
         (change)="onTextValueChange($event)">
</app-input>
