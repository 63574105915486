import { Component, Input } from '@angular/core';
import {AbstractControlDirective} from '@shared/directives/abstract-control.directive';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.css']
})
export class TextAreaComponent extends AbstractControlDirective {
  @Input() rows: number;
  @Input() disabled: boolean;
  @Input() autoResize: boolean;
}
