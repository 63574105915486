export class Color {
  constructor(public id: string = '',
              public parentColorId: string = '',
              public code: string = '',
              public nameNl: string = '',
              public nameEn: string = '',
              public nameDe: string = '',
              public nameFr: string = '') {}
  static adapt(item: any): Color {
    return new Color(
      item.id,
      item.parentColorId,
      item.code,
      item.nameNl,
      item.nameEn,
      item.nameDe,
      item.nameFr
    );
  }
}
