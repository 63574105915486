import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'div[app-product-icon-list]',
  templateUrl: './product-icon-list.component.html',
  styleUrls: ['./product-icon-list.component.css']
})
export class ProductIconListComponent implements OnInit {

  @Input() productIcons: string[];
  @Input() category: any;
  @Input() extension: any;

  constructor() { }

  ngOnInit(): void {
  }

}
