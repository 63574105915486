import {Customer} from '@shared/model/customer';

export class User {
  constructor(public id: number = 0,
              public fullName: string = '',
              public firstName: string = '',
              public auth0identifier: string = '',
              public lastName: string = '',
              public company: string = '',
              public customerName: string = '',
              public email: string = '',
              public lastAccess: Date = null,
              public picture: string = '',
              public createdOn: Date = null,
              public active: boolean = false,
              public customers: Customer[] = []) {
  }

  static adapt(item: any): User {
    return new User(
      item.id,
      item.fullName,
      item.firstName,
      item.auth0identifier,
      item.lastName,
      item.company,
      item.customerName,
      item.email,
      item.lastAccess,
      item.picture,
      item.createdOn,
      item.active,
      item.customers
    );
  }
}
