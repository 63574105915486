import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appRoutesLinks } from '@app/app.routes.links';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {
  errorCode;
  dashboardLink = appRoutesLinks.HOME;
  subscriptions: Subscription[] = [];

  public errorTitle: string;
  public errorDescription: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.initError();

    this.subscriptions.push(
      this.router.events.subscribe(() => {
        this.initError();
      }));
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private initError() {
    this.errorCode = +(this.route.snapshot.queryParams.errorCode || this.route.snapshot.data.errorCode || 500);

    const knownErrorCodes = [0, 400, 401, 403, 404, 500];

    if (!knownErrorCodes.some(errorCode => errorCode === this.errorCode)) {
      this.errorCode = 500;
    }

    this.errorTitle = this.translateService.instant(`error.${this.errorCode}.title`);
    this.errorDescription = this.translateService.instant(`error.${this.errorCode}.description`);
  }
}
