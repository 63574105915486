<form *ngIf="form" [formGroup]="form" novalidate>
  <div class="card">
    <span class="block text-900 font-bold text-xl mb-4">Vertegenwoordiger</span>
    <div class="grid grid-nogutter flex-wrap gap-3 p-fluid">
      <div class="col-12 lg:col-8">
        <div class="grid formgrid">
          <div class="col-12 md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="firstName" formControlName="firstName"/>
              <label for="firstName">Voornaam</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="lastName" formControlName="lastName"/>
              <label for="firstName">Achternaam</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="email" formControlName="email"/>
              <label for="firstName">Email</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="phone" formControlName="phone"/>
              <label for="phone">Telefoon</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="gsm" formControlName="gsm"/>
              <label for="phone">GSM</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="website" formControlName="website"/>
              <label for="phone">website</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="company" formControlName="company"/>
              <label for="company">company</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="street" formControlName="street"/>
              <label for="street">Straat</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="houseNumber" formControlName="houseNumber"/>
              <label for="houseNumber">huisnummer</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="city" formControlName="city"/>
              <label for="city">Gemeente</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="country" formControlName="country"/>
              <label for="country">country</label>
            </p-floatLabel>
          </div>
        </div>
      </div>

      <div class="flex-1 w-full lg:w-3 xl:w-4 flex flex-column row-gap-3 tw-mt-5">

        <div class="flex flex-column sm:flex-row justify-content-between align-items-center gap-3 py-2">
          <button pButton pRipple class="p-button-danger flex-1 p-button-outlined"
                  label="{{'user.edit.details.cancelButton' | translate }}" icon="pi pi-fw pi-arrow-circle-left"
                  (click)="goToOverview()"></button>
          <button pButton pRipple class="p-button-primary flex-1 border-round"
                  label="{{'user.edit.details.createButton' | translate }}" icon="pi pi-fw pi-check"
                  (click)="submit()"></button>
        </div>
      </div>
    </div>
  </div>
</form>
