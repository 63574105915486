import { Component } from '@angular/core';
import {ButtonDirective} from 'primeng/button';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {NgClass, NgForOf, NgSwitch} from '@angular/common';
import {ConfirmationService, MessageService, PrimeTemplate} from 'primeng/api';
import {Ripple} from 'primeng/ripple';
import {TableLazyLoadEvent, TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {Representative} from '@shared/model/representative';
import {Page} from '@shared/model/page';
import {Country, countryLabel} from '@shared/model/country';
import {Language} from '@shared/model/language';
import {Router} from '@angular/router';
import {BreadcrumbService} from '@core/services/breadcrumb.service';
import {LoadingIndicatorService} from '@core/services/loading-indicator.service';
import {appRoutesLinks} from '@app/app.routes.links';
import {RepresentativeService} from '@core/http/representative.service';
import {FormUtils} from '@core/utils/form.utils';
import {finalize} from 'rxjs';

@Component({
  selector: 'app-representative-overview',
  standalone: true,
  imports: [
    ButtonDirective,
    ConfirmDialogModule,
    NgForOf,
    PrimeTemplate,
    Ripple,
    TableModule,
    ToastModule,
    TooltipModule,
    TranslateModule,
    NgSwitch,
    NgClass
  ],
  templateUrl: './representative-overview.component.html',
  providers: [ConfirmationService]
})
export class RepresentativeOverviewComponent {

  public representatives: Representative[] = [];
  page: Page<Representative>;
  cols: any[];
  selectedRepresentative: any;
  loading = false;
  first = 0;
  countryLabel = countryLabel;
  currentLanguage: Language = Language.NL; // Default language, you may need to inject a language service here

  constructor(private router: Router,
              private breadcrumbService: BreadcrumbService,
              private loadingIndicatorService: LoadingIndicatorService,
              private confirmationService: ConfirmationService,
              private formUtils: FormUtils,
              private messageService: MessageService,
              private representativeService: RepresentativeService)
  {}

  ngOnInit(): void {
    this.loadingIndicatorService.show();
    this.breadcrumbService.setItemss([
      {
        icon: 'pi pi-home',
        route: appRoutesLinks.HOME
      },
      {
        label: 'Vertegenwoordigers',
      }
    ]);
    this.cols = [
      { field: 'firstName', header: 'Voornaam' },
      { field: 'lastName', header: 'Achternaam' },
      { field: 'country', header: 'Land' },
      { field: 'email', header: 'Email' },
      { field: 'company', header: 'Bedrijf' }
    ];

    // Initial load
    this.loadRepresentatives({ first: 0, rows: 20 });
  }

  loadRepresentatives(event: TableLazyLoadEvent) {
    this.loading = true;
    const page = event.first / event.rows;
    const size = event.rows;

    // Handle sorting
    let sort = '';
    if (event.sortField) {
      const direction = event.sortOrder === 1 ? 'asc' : 'desc';
      sort = `${event.sortField},${direction}`;
    }

    this.representativeService.readRepresentatives(page, size, sort)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.loadingIndicatorService.hide();
        })
      )
      .subscribe({
        next: (response) => {
          this.page = response;
          this.representatives = response.content;
          this.first = event.first;
        },
        error: (error) => {
          console.error('Error loading representatives:', error);
          this.loading = false;
          this.loadingIndicatorService.hide();
        }
      });
  }

  edit(id: number) {
    this.router.navigate(appRoutesLinks.REPRESENTATIVE_EDIT(id));
  }

  calculateRowNumber(rowIndex: number): number {
    const pageSize = this.page?.pageSize || 20; // default to 20 if undefined
    const pageNumber = this.page?.pageNumber || 0; // default to 0 if undefined
    return (pageNumber * pageSize) + rowIndex + 1;
  }

  delete(id: number, name: string) {
    this.confirmationService.confirm({
      message: 'Bent u zeker dat u de vertegenwoordiger ' + name +  ' wilt verwijderen?',
      header: 'Bevestigen',
      acceptLabel: 'Ja',
      rejectLabel: 'Nee',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingIndicatorService.show();
        this.representativeService.deleteRepresentative(id).subscribe(result => {
          this.representativeService.readRepresentatives().subscribe(page => {
            this.page = page;
            this.representatives = page.content;
            this.loadingIndicatorService.hide();
            this.formUtils.showSuccessMessageWithLife('Vertegenwoordiger verwijderd', 'We hebben de vertegenwoordiger ' + name +  ' veilig kunnen verwijderen!', 2000);
          });
        });
      }
    });
  }
}
