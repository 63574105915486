<p-card [header]="'Basis'" [formGroup]="form">
  <div class="grid">
    <p-floatLabel class="mx-2 flex align-items-center m-3">
      <p-multiSelect
        id="stands"
        inputId="stands"
        formControlName="stands"
        [showHeader]="false"
        [showClear]="true"

        [filter]="false"
        [options]="stands"
        [ariaLabel]="'taxonomy.genus.properties.lifetime.title' | translate">
      </p-multiSelect>
      <label for="stands">{{ 'taxonomy.genus.properties.stand.title' | translate }}</label>
    </p-floatLabel>

    <p-floatLabel class="mx-2 flex align-items-center m-3">
      <p-multiSelect
        id="soils"
        inputId="soils"
        formControlName="soils"
        [showClear]="true"
        [filter]="true"
        filterBy="nameNl"
        optionValue="id"
        [optionLabel]="soilLabel2"
        [options]="soils">
      </p-multiSelect>
      <label for="soils">{{ 'taxonomy.genus.properties.soil.title' | translate }}</label>
    </p-floatLabel>

    <p-floatLabel class="mx-2 flex align-items-center m-3">
      <p-multiSelect
        id="diseases"
        inputId="diseases"
        formControlName="diseases"
        [showClear]="true"
        [filter]="true"
        filterBy="nameNl"
        optionValue="id"
        [optionLabel]="diseaseLabel2"
        [options]="diseases | orderBy: 'nameNl'">
      </p-multiSelect>
      <label for="diseases">{{ 'taxonomy.genus.properties.disease.title' | translate }}</label>
    </p-floatLabel>

    <p-floatLabel class="mx-2 flex align-items-center m-3">
    <p-dropdown formControlName="fertilization"
                [showClear]="true"
                [checkmark]="true"
                [options]="fertilizations"
                id="fertilization"
    ></p-dropdown>
      <label for="fertilization">{{ 'taxonomy.genus.properties.fertilization.title' | translate }}</label>
    </p-floatLabel>

    <div class="flex align-items-stretch flex-wrap my-5">
      <p-floatLabel class="mx-2 flex align-items-center m-3">
        <p-inputNumber
          formControlName="fromSowingTemperature"
          prefix="↓ "
          id="fromSowingTemperature"
          suffix="℃"
          [min]="0"
          [max]="40"/>
        <label for="fromSowingTemperature">{{ 'taxonomy.genus.properties.fromSowingTemperature' | translate }}</label>
      </p-floatLabel>
      <p-floatLabel class="mx-2 flex align-items-center m-3">
        <p-inputNumber
          formControlName="tillSowingTemperature"
          prefix="↑ "
          id="tillSowingTemperature"
          suffix="℃"
          [min]="0"
          [max]="40"/>
        <label for="tillSowingTemperature">{{ 'taxonomy.genus.properties.tillSowingTemperature' | translate }}</label>
      </p-floatLabel>
      <p-floatLabel class="mx-2 flex align-items-center m-3">
        <p-inputNumber
          formControlName="fromPottingTemperature"
          prefix="↓ "
          id="fromPottingTemperature"
          suffix="℃"
          [min]="0"
          [max]="40"/>
        <label for="fromPottingTemperature">{{ 'taxonomy.genus.properties.fromPottingTemperature' | translate }}</label>
      </p-floatLabel>
      <p-floatLabel class="mx-2 flex align-items-center m-3">
        <p-inputNumber
          formControlName="tillPottingTemperature"
          prefix="↑ "
          id="tillPottingTemperature"
          suffix="℃"
          [min]="0"
          [max]="40"/>
        <label for="tillPottingTemperature">{{ 'taxonomy.genus.properties.tillPottingTemperature' | translate }}</label>
      </p-floatLabel>
    </div>
  </div>
</p-card>

<p-card header="Zaaiperiode" class="col-12" [formGroup]="form">
  <div class="grid">
    <p-floatLabel class="col-6 my-4">
      <p-dropdown formControlName="fromSowingMonth"
                  [showClear]="true"
                  [checkmark]="true"
                  placeholder="{{'taxonomy.specie.properties.genus.select' | translate }}"
                  [options]="months"
                  id="fromSowingMonth"
      ></p-dropdown>
      <label for="fromSowingMonth">{{ 'taxonomy.genus.properties.fromSowingMonth.label' | translate }}</label>
    </p-floatLabel>

    <p-floatLabel class="col-6 my-4">
      <p-dropdown formControlName="tillSowingMonth"
                  [showClear]="true"
                  [checkmark]="true"
                  placeholder="{{'taxonomy.genus.properties.tillSowingMonth.label' | translate }}"
                  [options]="months"
                  id="tillSowingMonth"
      ></p-dropdown>
      <label for="tillSowingMonth">{{ 'taxonomy.genus.properties.tillSowingMonth.label' | translate }}</label>
    </p-floatLabel>

    <p-floatLabel class="col-6 my-4">
      <p-dropdown formControlName="fromSowingSecondMonth"
                  [showClear]="true"
                  [checkmark]="true"
                  placeholder="{{'taxonomy.genus.properties.fromSowingSecondMonth.label' | translate }}"
                  [options]="months"
                  id="fromSowingSecondMonth"
      ></p-dropdown>
      <label for="fromSowingSecondMonth">{{ 'taxonomy.genus.properties.fromSowingSecondMonth.label' | translate }}</label>
    </p-floatLabel>

    <p-floatLabel class="col-6 my-4">
      <p-dropdown formControlName="tillSowingSecondMonth"
                  [showClear]="true"
                  [checkmark]="true"
                  placeholder="{{'taxonomy.genus.properties.tillSowingSecondMonth.label' | translate }}"
                  [options]="months"
                  id="tillSowingSecondMonth"
      ></p-dropdown>
      <label for="tillSowingSecondMonth">{{ 'taxonomy.genus.properties.tillSowingSecondMonth.label' | translate }}</label>
    </p-floatLabel>

  </div>
</p-card>
<p-card header="Potperiode" class="col-12" [formGroup]="form">
  <div class="grid">

    <p-floatLabel class="col-12 lg:col-6 md-3 my-3">
      <p-dropdown formControlName="fromPottingMonth"
                  [showClear]="true"
                  [checkmark]="true"
                  placeholder="{{'taxonomy.genus.properties.fromPottingMonth.label' | translate }}"
                  [options]="months"
                  id="fromPottingMonth"
      ></p-dropdown>
      <label for="fromPottingMonth">{{ 'taxonomy.genus.properties.fromPottingMonth.label' | translate }}</label>
    </p-floatLabel>

    <p-floatLabel class="col-12 lg:col-6 md-3 my-3">
      <p-dropdown formControlName="tillPottingMonth"
                  [showClear]="true"
                  [checkmark]="true"
                  placeholder="{{'taxonomy.genus.properties.tillPottingMonth.label' | translate }}"
                  [options]="months"
                  id="tillPottingMonth"
      ></p-dropdown>
      <label for="tillPottingMonth">{{ 'taxonomy.genus.properties.tillPottingMonth.label' | translate }}</label>
    </p-floatLabel>

    <p-floatLabel class="col-12 lg:col-6 md-3 my-3">
      <p-dropdown formControlName="fromPottingSecondMonth"
                  [showClear]="true"
                  [checkmark]="true"
                  placeholder="{{'taxonomy.genus.properties.fromPottingSecondMonth.label' | translate }}"
                  [options]="months"
                  id="fromPottingSecondMonth"
      ></p-dropdown>
      <label for="fromPottingSecondMonth">{{ 'taxonomy.genus.properties.fromPottingSecondMonth.label' | translate }}</label>
    </p-floatLabel>

    <p-floatLabel class="col-12 lg:col-6 md-3 my-3">
      <p-dropdown formControlName="tillPottingSecondMonth"
                  [showClear]="true"
                  [checkmark]="true"
                  placeholder="{{'taxonomy.genus.properties.tillPottingSecondMonth.label' | translate }}"
                  [options]="months"
                  id="tillPottingSecondMonth"
      ></p-dropdown>
      <label for="tillPottingSecondMonth">{{ 'taxonomy.genus.properties.tillPottingSecondMonth.label' | translate }}</label>
    </p-floatLabel>

  </div>
</p-card>
<p-card header="Verkoopsperiode" class="col-12" [formGroup]="form">
  <div class="grid">

    <p-floatLabel class="col-12 lg:col-6 md-3 my-3">
      <p-dropdown formControlName="fromSalesMonth"
                  [showClear]="true"
                  [checkmark]="true"
                  placeholder="{{'taxonomy.genus.properties.fromSalesMonth.label' | translate }}"
                  [options]="months"
                  id="fromSalesMonth"
      ></p-dropdown>
      <label for="fromSalesMonth">{{ 'taxonomy.genus.properties.fromSalesMonth.label' | translate }}</label>
    </p-floatLabel>

    <p-floatLabel class="col-12 lg:col-6 md-3 my-3">
      <p-dropdown formControlName="tillSalesMonth"
                  [showClear]="true"
                  [checkmark]="true"
                  placeholder="{{'taxonomy.genus.properties.tillSalesMonth.label' | translate }}"
                  [options]="months"
                  id="tillSalesMonth"
      ></p-dropdown>
      <label for="tillSalesMonth">{{ 'taxonomy.genus.properties.tillSalesMonth.label' | translate }}</label>
    </p-floatLabel>

    <p-floatLabel class="col-12 lg:col-6 md-3 my-3">
      <p-dropdown formControlName="fromSalesSecondMonth"
                  [showClear]="true"
                  [checkmark]="true"
                  placeholder="{{'taxonomy.genus.properties.fromSalesSecondMonth.label' | translate }}"
                  [options]="months"
                  id="fromSalesSecondMonth"
      ></p-dropdown>
      <label for="fromSalesSecondMonth">{{ 'taxonomy.genus.properties.fromSalesSecondMonth.label' | translate }}</label>
    </p-floatLabel>
    <p-floatLabel class="col-12 lg:col-6 md-3 my-3">
      <p-dropdown formControlName="tillSalesSecondMonth"
                  [showClear]="true"
                  [checkmark]="true"
                  placeholder="{{'taxonomy.genus.properties.tillSalesSecondMonth.label' | translate }}"
                  [options]="months"
                  id="tillSalesSecondMonth"
      ></p-dropdown>
      <label for="tillSalesSecondMonth">{{ 'taxonomy.genus.properties.tillSalesSecondMonth.label' | translate }}</label>
    </p-floatLabel>
  </div>
</p-card>
