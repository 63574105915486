export class Representative {
  constructor(
    public id: number = 0,
    public firstName: string = '',
    public lastName: string = '',
    public phone: string = '',
    public gsm: string = '',
    public fax: string = '',
    public email: string = '',
    public street: string = '',
    public houseNumber: string = '',
    public city: string = '',
    public country: string = '',
    public company: string = '',
    public website: string = '',
    public createdBy: string = '',
    public createdOn: Date | null = null,
    public modifiedBy: string = '',
    public modifiedOn: Date | null = null
  ) {}

  static adapt(item: any): Representative {
    return new Representative(
      item.id,
      item.firstName,
      item.lastName,
      item.phone,
      item.gsm,
      item.fax,
      item.email,
      item.street,
      item.houseNumber,
      item.city,
      item.country,
      item.company,
      item.website,
      item.createdBy,
      item.createdOn ? new Date(item.createdOn) : null,
      item.modifiedBy,
      item.modifiedOn ? new Date(item.modifiedOn) : null
    );
  }
}
