export const bridgeRoutesNames = {
  OVERVIEW: '',
  PRODUCT_OVERVIEW: 'product',
  PRODUCT_EDIT: 'product/:id/edit',
  ACTION_EDIT: 'action/:id/edit',
  CUSTOMER_OVERVIEW: 'customer',
  CUSTOMER_EDIT: 'customer/:id/edit',
  STOCK_OVERVIEW: 'stock',
  STOCK_EDIT: 'stock/:id/edit',
};
