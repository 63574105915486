import {Injectable, Injector} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {LoadingIndicatorComponent} from '@shared/layout/loading-indicator/loading-indicator.component';
import {ComponentPortal} from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {
  overlayRef: OverlayRef;
  timeout: any;

  constructor(
    private injector: Injector,
    private overlay: Overlay) {}

  show(delay = 300) {
    this.timeout = setTimeout(() => {
      const componentPortal = new ComponentPortal(LoadingIndicatorComponent);
      this.overlayRef = this.createOverlay();
      this.overlayRef.attach(componentPortal);
    }, delay);
  }

  hide() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }

  private createOverlay() {
    const overlayConfig = this.getOverlayConfig();
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
  }
}
