import {Component, EventEmitter, Input, Optional, Output, Self} from '@angular/core';
import {AbstractControlDirective} from '@shared/directives/abstract-control.directive';
import {AbstractControl, NgControl} from '@angular/forms';
import {ValidationUtil} from '@shared/validation/validation-util.service';
import {InputType} from '@shared/components/input/input-type.enum';

@Component({
  selector: 'app-temperature-slider',
  templateUrl: './temperature-slider.component.html',
  styleUrls: ['./temperature-slider.component.css']
})
export class TemperatureSliderComponent extends AbstractControlDirective {
  @Input() orientation: InputType = InputType.VERTICAL;
  @Output() onValueChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Self() @Optional() public ngControl: NgControl,
              private validationUtil: ValidationUtil) {
    super(ngControl);
  }

  get required(): boolean {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);

      return validator && validator.required;
    }

    return false;
  }

  get hasErrors(): boolean {
    return this.control.dirty && !this.control.valid;
  }

  get error(): string {
    if (this.hasErrors && this.control?.errors !== null && Object.keys(this.control.errors).length !== 0) {
      const firstError = Object.keys(this.control.errors)[0];
      return this.validationUtil.handleError(firstError, this.control.errors[firstError]);
    }
  }

  onValueChange($event: any) {
    this.onValueChanged.emit($event.values);
  }
}
