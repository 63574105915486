import {Component, ElementRef, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {LayoutService} from './service/app.layout.service';
import {Router} from '@angular/router';
import {appRoutesLinks} from '@app/app.routes.links';
import {AuthenticationService} from '@core/services/authentication.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

  items!: MenuItem[];

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  constructor(public layoutService: LayoutService, private router: Router, private authService: AuthenticationService
  ) {
  }

  navigateToProfile() {
    this.router.navigate(appRoutesLinks.ACCOUNT_PROFILE);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(appRoutesLinks.LOGIN);
  }
}
