<app-input
  [label]="label"
  [control]="control">
  <p-listbox
    [formControl]="control"
    [options]="options"
    checkbox = "true"
    [style]="{'width':'100%'}">
  </p-listbox>
</app-input>
