<app-input
  [label]="label"
  [control]="control">
  <p-dropdown
    [formControl]="control"
    [options]="options"
    [placeholder]="placeholder"
    [style]="{'width':'100%'}"
    [showClear]="showClear"
    (onChange)="onDropdownChange($event)"
  >
  </p-dropdown>
</app-input>
