import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-genus-specie-periods',
  templateUrl: './genus-specie-periods.component.html',
  styleUrls: ['./genus-specie-periods.component.css']
})
export class GenusSpeciePeriodsComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
