export enum FruitShape {
  NONE = 'NONE' as any,
  COCKTAIL = 'COCKTAIL' as any,
  LARGE_ROUND_FRUITS = 'LARGE_ROUND_FRUITS' as any,
  HORN_SHAPED = 'HORN_SHAPED' as any,
  HEART_SHAPED = 'HEART_SHAPED' as any,
  OLIVE_SHAPED = 'OLIVE_SHAPED' as any,
  ROUND_FRUITS = 'ROUND_FRUITS' as any,
  MARMANDE = 'MARMANDE' as any,
  COEUR_DE_BOEUF = 'COEUR_DE_BOEUF' as any,
  TRUSS_TOMATOES = 'TRUSS_TOMATOES' as any,
  BLOCKY = 'BLOCKY' as any,
  LONG_POINT = 'LONG_POINT' as any,
  SPECIALS = 'SPECIALS' as any,
  LIGURIA = 'LIGURIA' as any,
}
