<app-input
  [label]="label"
  [control]="control"
>
  <p-calendar
    [formControl]="control"
    [selectionMode]="selectionMode"
    [dateFormat]="dateFormat"
    [readonlyInput]="readonlyInput"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [style]="{'width':'100%'}"
    [inputStyle]="{'width':'100%'}"
    [yearNavigator]="yearNavigator"
    [numberOfMonths]="numberOfMonths"
    [view]="view"
    [yearRange]="yearRange"
    [monthNavigator]="monthNavigator"
  ></p-calendar>
</app-input>
