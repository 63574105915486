import {Routes} from '@angular/router';
import {appRoutesNames} from './app.routes.names';
import {ErrorComponent} from '@shared/components/error/error.component';
import {AuthGuard} from '@core/guards/auth.guard';
import {LoginComponent} from '@features/general/pages/login/login.component';
import {PermissionGuard} from '@core/guards/permission.guard';
import {AppLayoutComponent} from '@app/layout/app.layout.component';
import {RepresentativeOverviewComponent} from '@features/representative/representative-overview/representative-overview.component';
import {RepresentativeCreateComponent} from '@features/representative/representative-create/representative-create.component';
import {RepresentativeEditComponent} from '@features/representative/representative-edit/representative-edit.component';
import {SlideshowEditComponent} from '@features/slideshow/slideshow-edit/slideshow-edit.component';
import {SlideshowCreateComponent} from '@features/slideshow/slideshow-create/slideshow-create.component';
import {SlideshowOverviewComponent} from '@features/slideshow/slideshow-overview/slideshow-overview.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['access:admin-panel']
    },
    children: [
      {
        path: appRoutesNames.REPRESENTATIVE, data: {breadcrumb: 'Vertegenwoordigers'},
        component: RepresentativeOverviewComponent
      },
      {
        path: appRoutesNames.REPRESENTATIVE_CREATE, data: {breadcrumb: 'Vertegenwoordigers'},
        component: RepresentativeCreateComponent
      },
      {
        path: appRoutesNames.REPRESENTATIVE_EDIT, data: {breadcrumb: 'Vertegenwoordigers'},
        component: RepresentativeEditComponent
      },
      {
        path: appRoutesNames.SLIDESHOW, data: {breadcrumb: 'slideshow'},
        component: SlideshowOverviewComponent
      },
      {
        path: appRoutesNames.SLIDESHOW_CREATE, data: {breadcrumb: 'slideshow'},
        component: SlideshowCreateComponent
      },
      {
        path: appRoutesNames.SLIDESHOW_EDIT, data: {breadcrumb: 'slideshow'},
        component: SlideshowEditComponent
      },
      {
        path: appRoutesNames.BRIDGE, data: {breadcrumb: 'Bridge'},
        loadChildren: () => import('./features/bridge/bridge.module').then(m => m.BridgeModule),
      },
      {
        path: appRoutesNames.HOME, data: {breadcrumb: 'Dashboard'},
        loadChildren: () => import('./features/general/general.module').then(m => m.GeneralModule),
      },
      {
        path: appRoutesNames.AVAILABILITY, data: {breadcrumb: 'Beschikbaarheden'},
        loadChildren: () => import('./features/availability/availability.module').then(m => m.AvailabilityModule),
      },
      {
        path: appRoutesNames.IMAGEDB, data: {breadcrumb: 'Foto Beheer'},
        loadChildren: () => import('./features/imagedb/imagedb.module').then(m => m.ImagedbModule),
      },
      {
        path: appRoutesNames.DIGITALCATALOG, data: {breadcrumb: 'Digitale Catalogi'},
        loadChildren: () => import('./features/digitalcatalog/digitalcatalog.module').then(m => m.DigitalcatalogModule),
      },
      {
        path: appRoutesNames.XMLCATALOG,
        loadChildren: () => import('./features/xmlcatalog/xmlcatalog.module').then(m => m.XmlcatalogModule),
      },
      {
        path: appRoutesNames.ORDERFORM, data: {breadcrumb: 'Bestelformulieren'},
        loadChildren: () => import('./features/orderform/orderform.module').then(m => m.OrderformModule),
      },
      {
        path: appRoutesNames.ORDER, data: {breadcrumb: 'Bestellingen'},
        loadChildren: () => import('./features/order/order.module').then(m => m.OrderModule),
      },
      {
        path: appRoutesNames.ACCOUNT,
        loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule),
      },
      {
        path: appRoutesNames.USER, data: {breadcrumb: 'Gebruikers'},
        loadChildren: () => import('./features/user/user.module').then(m => m.UserModule),
      },
      {
        path: appRoutesNames.JOB, data: {breadcrumb: 'Vacatures'},
        loadChildren: () => import('./features/job/job.module').then(m => m.JobModule),
      },
      {
        path: appRoutesNames.NEWSITEM, data: {breadcrumb: 'Nieuwsberichten'},
        loadChildren: () => import('./features/newsitem/newsitem.module').then(m => m.NewsitemModule),
      },
      {
        path: appRoutesNames.CONTACTINFO, data: {breadcrumb: 'Contact Aanvragen'},
        loadChildren: () => import('./features/contactinfo/contactinfo.module').then(m => m.ContactinfoModule),
      },
      {
        path: appRoutesNames.TAXONOMY, data: {breadcrumb: 'Taxonomie'},
        loadChildren: () => import('./features/taxonomy/taxonomy.module').then(m => m.TaxonomyModule),
      },
      {
        path: appRoutesNames.TRANSLATION, data: {breadcrumb: 'Vertalingen'},
        loadChildren: () => import('./features/translation/translation.module').then(m => m.TranslationModule),
      },
      {
        path: appRoutesNames.CUSTOMER, data: {breadcrumb: 'Klanten'},
        loadChildren: () => import('./features/customer/customer.module').then(m => m.CustomerModule),
      }
    ]
  },
  {
    path: appRoutesNames.LOGIN,
    component: LoginComponent,
  },
  {
    path: appRoutesNames.ERROR,
    component: ErrorComponent
  },
  {
    path: '**',
    component: ErrorComponent,
    data: {
      errorCode: 404
    }
  }
];
