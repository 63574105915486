import {Component} from '@angular/core';
import {ButtonDirective} from 'primeng/button';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {NgClass, NgForOf, NgSwitch} from '@angular/common';
import {ConfirmationService, MessageService, PrimeTemplate} from 'primeng/api';
import {Ripple} from 'primeng/ripple';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {Page} from '@shared/model/page';
import {Router} from '@angular/router';
import {BreadcrumbService} from '@core/services/breadcrumb.service';
import {LoadingIndicatorService} from '@core/services/loading-indicator.service';
import {appRoutesLinks} from '@app/app.routes.links';
import {Slide} from '@shared/model/slide';
import {SlideshowItemService} from '@core/http/slideshow.service';
import {FormUtils} from '@core/utils/form.utils';

@Component({
  selector: 'app-slideshow-overview',
  standalone: true,
  imports: [
    ButtonDirective,
    ConfirmDialogModule,
    NgForOf,
    PrimeTemplate,
    Ripple,
    TableModule,
    ToastModule,
    TooltipModule,
    NgSwitch,
    NgClass
  ],
  templateUrl: './slideshow-overview.component.html',
  providers: [ConfirmationService]
})
export class SlideshowOverviewComponent {

  public slides: Slide[] = [];
  page: Page<Slide>;
  cols: any[];
  selectedRepresentative: any;

  constructor(private router: Router,
              private breadcrumbService: BreadcrumbService,
              private loadingIndicatorService: LoadingIndicatorService,
              private confirmationService: ConfirmationService,
              private formUtils: FormUtils,
              private messageService: MessageService,
              private slideshowItemService: SlideshowItemService)
  {}

  ngOnInit(): void {
    this.loadingIndicatorService.show();
    this.breadcrumbService.setItemss([
      {
        icon: 'pi pi-home',
        route: appRoutesLinks.HOME
      },
      {
        label: 'slideshow',
      }
    ]);
    this.cols = [
      { field: 'raescode', header: 'plant Code' },
      { field: 'title', header: 'Plant' },
      { field: 'slideCategory', header: 'category' },
      { field: 'active', header: 'active' }
    ];
    this.slideshowItemService.readSlideshowItems().subscribe(page => {
      this.page = page;
      this.slides = page.content;
      this.slides.forEach(rep => console.log(rep));
      this.loadingIndicatorService.hide();

    });
  }
  edit(id: number) {
    this.router.navigate(appRoutesLinks.SLIDESHOW_EDIT(id));
  }

  delete(id: number, name: string) {
    this.confirmationService.confirm({
      message: 'Bent u zeker dat u de slide ' + name +  ' wilt verwijderen?',
      header: 'Bevestigen',
      acceptLabel: 'Ja',
      rejectLabel: 'Nee',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingIndicatorService.show();
        this.slideshowItemService.deleteSlide(id).subscribe(result => {
          this.slideshowItemService.readSlideshowItems().subscribe(page => {
            this.page = page;
            this.slides = page.content;
            this.loadingIndicatorService.hide();
            this.formUtils.showSuccessMessageWithLife('Slide verwijderd', 'We hebben de slide ' + name +  ' veilig kunnen verwijderen!', 2000);
          });
        });
      }
    });
  }

}
