import {Component} from '@angular/core';
import {ButtonDirective} from 'primeng/button';
import {FloatLabelModule} from 'primeng/floatlabel';
import {InputTextModule} from 'primeng/inputtext';
import {NgIf} from '@angular/common';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Ripple} from 'primeng/ripple';
import {SharedModule} from '@shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {LoadingIndicatorService} from '@core/services/loading-indicator.service';
import {FormUtils} from '@core/utils/form.utils';
import {BreadcrumbService} from '@core/services/breadcrumb.service';
import {appRoutesLinks} from '@app/app.routes.links';
import {flatMap} from 'rxjs/operators';
import {Slide} from '@shared/model/slide';
import {SlideshowItemService} from '@core/http/slideshow.service';
import {InputSwitchModule} from 'primeng/inputswitch';
import {SlideCategory} from '@shared/model/slideCategory';
import {OptionService} from '@core/services/option.service';
import {PlantService} from '@core/http/plant.service';
import {Plant} from '@shared/model/plant';

@Component({
  selector: 'app-slideshow-edit',
  standalone: true,
  imports: [
    ButtonDirective,
    FloatLabelModule,
    InputTextModule,
    NgIf,
    ReactiveFormsModule,
    Ripple,
    SharedModule,
    TranslateModule,
    InputSwitchModule
  ],
  templateUrl: './slideshow-edit.component.html'
})
export class SlideshowEditComponent {
  public slide: Slide = new Slide();
  public slideSubscription$: Observable<Slide>;

  public form: FormGroup;
  public slideCategories: SlideCategory[] = [];
  plants: any[];
  optionLabelPlant: string | ((option: Plant) => string) = this.plantLabel;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private optionService: OptionService,
    private slideshowItemService: SlideshowItemService,
    private plantService: PlantService,
    private formBuilder: FormBuilder,
    private loadingIndicatorService: LoadingIndicatorService,
    private formUtils: FormUtils,
    private breadcrumbService: BreadcrumbService) {
  }

  public plantLabel(option: Plant): string {
    return option.fullnameEn;
  }

  ngOnInit(): void {

    this.optionService.getSlideCategories().subscribe(slideCategories => this.slideCategories = slideCategories),

      this.breadcrumbService.setItemss([
        {
          icon: 'pi pi-home',
          route: appRoutesLinks.HOME
        },
        {
          label: 'Slide',
          route: appRoutesLinks.SLIDESHOW_OVERVIEW
        },
        {
          label: 'Slide Aanpassen'
        }
      ]);

    this.plantService.retrievePlantsForDropDown().subscribe(results => {
      this.plants = results;
    });

    this.slideSubscription$ = this.route.paramMap.pipe(
      flatMap((params: ParamMap) => this.slideshowItemService.show(Number(params.get('id'))))
    );

    this.slideSubscription$.subscribe(result => {
      this.slide = result;
      this.initForm();
      this.loadingIndicatorService.hide();
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      title: [{value: this.slide.title, disabled: true }],
      slideCategory: [this.slide.slideCategory],
      plantId: [this.slide.plantId],
      raescode: [{value: this.slide.raescode, disabled: true}],
      type: [this.slide.type],
      image: [this.slide.image],
      categoryId: [this.slide.categoryId],
      routerLink: [this.slide.routerLink],
      active: [this.slide.active]
    });
  }

  public submit() {
    if (this.form.valid) {
      this.loadingIndicatorService.show();
      const slide = this.form.getRawValue();
      console.log(slide.plantId);
      if (this.slide.plantId !== null && this.slide.plantId === ''){
        slide.plantId = null;
      }
      console.log(slide.plantId);
      this.slideshowItemService.editSlideshowItem(this.slide.id, slide).subscribe(result => {
        this.router.navigate(appRoutesLinks.SLIDESHOW_OVERVIEW);
        this.loadingIndicatorService.hide();
        this.formUtils.showSuccessMessage('slide aangepast', 'We konden succesvol de slide aanpassen');
      });
    } else {
      this.formUtils.validateAllFormFields(this.form);

    }
  }

  goToOverview() {
    this.router.navigate(appRoutesLinks.SLIDESHOW_OVERVIEW);
  }
}
