import {Component} from '@angular/core';
import {Slide} from '@shared/model/slide';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {SlideshowItemService} from '@core/http/slideshow.service';
import {LoadingIndicatorService} from '@core/services/loading-indicator.service';
import {FormUtils} from '@core/utils/form.utils';
import {BreadcrumbService} from '@core/services/breadcrumb.service';
import {appRoutesLinks} from '@app/app.routes.links';
import {ButtonDirective} from 'primeng/button';
import {FloatLabelModule} from 'primeng/floatlabel';
import {InputTextModule} from 'primeng/inputtext';
import {NgIf} from '@angular/common';
import {Ripple} from 'primeng/ripple';
import {SharedModule} from '@shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {InputSwitchModule} from 'primeng/inputswitch';
import {PlantService} from '@core/http/plant.service';
import {OptionService} from '@core/services/option.service';
import {SlideCategory} from '@shared/model/slideCategory';
import {Plant} from '@shared/model/plant';

@Component({
  selector: 'app-slideshow-create',
  standalone: true,
  imports: [
    ButtonDirective,
    FloatLabelModule,
    InputTextModule,
    NgIf,
    ReactiveFormsModule,
    Ripple,
    SharedModule,
    TranslateModule,
    InputSwitchModule
  ],
  templateUrl: './slideshow-create.component.html'
})
export class SlideshowCreateComponent {
  public slide: Slide = new Slide();
  public form: FormGroup;
  public slideCategories: SlideCategory[] = [];
  plants: any[];
  optionLabelPlant: string | ((option: Plant) => string) = this.plantLabel;

  constructor(
    private router: Router,
    private slideshowItemService: SlideshowItemService,
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private optionService: OptionService,
    private loadingIndicatorService: LoadingIndicatorService,
    private formUtils: FormUtils,
    private breadcrumbService: BreadcrumbService) {
  }

  ngOnInit(): void {
    this.breadcrumbService.setItemss([
      {
        icon: 'pi pi-home',
        route: appRoutesLinks.HOME
      },
      {
        label: 'Slideshow',
        route: appRoutesLinks.SLIDESHOW_OVERVIEW
      },
      {
        label: 'slide Aanmaken'
      }
    ]);

    this.plantService.retrievePlantsForDropDown().subscribe(results => {
      this.plants = results;
    });

    this.initForm();
    this.optionService.getSlideCategories().subscribe(slideCategories => this.slideCategories = slideCategories);

  }

  private initForm() {
    this.form = this.formBuilder.group({
      title: [{value: this.slide.title, disabled: true}],
      slideCategory: [this.slide.slideCategory],
      plantId: [this.slide.plantId],
      active: [this.slide.active],
      image: [this.slide.image]
    });
  }

  public submit() {
    if (this.form.valid) {
      this.loadingIndicatorService.show();
      const slide = this.form.getRawValue();

      this.slideshowItemService.create(slide).subscribe(result => {
        this.router.navigate(appRoutesLinks.SLIDESHOW_OVERVIEW);
        this.loadingIndicatorService.hide();
        this.formUtils.showSuccessMessage('slide aangemaakt', 'We konden succesvol de slide aanmaken');
      });
    } else {
      this.formUtils.validateAllFormFields(this.form);

    }
  }

  public plantLabel(option: Plant): string {
    return option.fullnameEn;
  }

  goToOverview() {
    this.router.navigate(appRoutesLinks.SLIDESHOW_OVERVIEW);
  }
}
