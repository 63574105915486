export enum EventType {
  ProductCreatedEvent = 'ProductCreatedEvent',
  ProductUpdatedEvent = 'ProductUpdatedEvent',
  ProductDeletedEvent = 'ProductDeletedEvent',
  CompanyDetailsChangedEvent = 'CompanyDetailsChangedEvent',
  ContactPersonChangedEvent = 'ContactPersonChangedEvent',
  CustomerDeletedEvent = 'CustomerDeletedEvent',
  CustomerLanguageChangedEvent = 'CustomerLanguageChangedEvent',
  CustomerRegisteredEvent = 'CustomerRegisteredEvent',
  OfficialAddressChangedEvent = 'OfficialAddressChangedEvent'
}
