import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserProfile} from '@shared/model/auth/user-profile';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  private readonly TRANSLATE_FOR_PREDICATE = 'update:translations:';

  private userSource = new ReplaySubject<UserProfile>();
  private user: UserProfile;

  public hasAnyPermission$(requiredPermissions: string[]): Observable<boolean> {
    return this.getPermissions$().pipe(
      map(permissions => requiredPermissions.some(permission => permissions.includes(permission)))
    );
  }

  public hasPermission$(requiredPermission: string): Observable<boolean> {
    return this.getPermissions$().pipe(
      map(permissions => permissions.includes(requiredPermission))
    );
  }

  public hasAnyPermission(requiredPermissions: string[]): boolean {
    return requiredPermissions.some(permission => this.getPermissions().includes(permission));
  }

  public hasPermission(requiredPermission: string): boolean {
    return this.getPermissions().includes(requiredPermission);
  }

  public getTranslatableLanguages(): string[] {
    return this.getPermissions()
      .filter(p => p.startsWith(this.TRANSLATE_FOR_PREDICATE))
      .map(p => p.substring(this.TRANSLATE_FOR_PREDICATE.length))
      .map(p => p.toUpperCase());
  }

  getUser(): Observable<UserProfile> {
    return this.userSource.asObservable();
  }

  setUser(user: UserProfile): void {
    this.user = user;
    this.userSource.next(user);
  }

  getPermissions$(): Observable<string[]> {
    return this.getUser().pipe(
      map(user => user.permissions)
    );
  }

  getPermissions(): string[] {
    if (this.user === null || this.user === undefined) {
      return [];
    }

    return this.user?.permissions;
  }
}
