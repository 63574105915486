import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControlDirective} from '@shared/directives/abstract-control.directive';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']

})
export class TextComponent extends AbstractControlDirective {

  @Output() onTextValueChanged: EventEmitter<any> = new EventEmitter<any>();

  onTextValueChange($event: any) {
    this.onTextValueChanged.emit($event.target.value);
  }
}
