import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AppConfigService} from '../providers/app-config.service';
import {Page} from '@shared/model/page';
import {Plant} from '@shared/model/plant';
import {ImportResult} from '@shared/model/import-result';
import {ProductImportUploadParameters} from '@core/http/product.service';
import {Selectedcultivar} from '@shared/model/selectedcultivar';

@Injectable({
  providedIn: 'root'
})
export class PlantService {
  private readonly _privateApi: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this._privateApi = this.appConfig.config().privateApiBase;
  }
  public fetchPagedPlantList(searchParameters: PlantSearchParameters): Observable<Page<Plant>> {
    return this.http.get<Page<Plant>>(`${this._privateApi}/plants/paged`, { params: searchParameters.build() }).pipe(
      map(page => Page.adapt(page, page.content.map(Plant.adapt)))
    );
  }

  public findById(id: number): Observable<Plant> {
    return this.http.get<Plant>(`${this._privateApi}/plants/${id}`).pipe(
      map(Plant.adapt)
    );
  }

  updatePlant(plantId: number, plant: Plant): Observable<Plant> {
    const plantUrl = `${this._privateApi}/plants/${plantId}`;
    return this.http.put<any>(plantUrl, plant).pipe(
      map(Plant.adapt)
    );
  }

  uploadPlantList(parameters: ProductImportUploadParameters, file: File): Observable<ImportResult> {
    const data = new FormData();

    data.append('excelfile', file);

    return this.http.post<ImportResult>(`${this._privateApi}/import/excel/plant`, data).pipe(
      map(ImportResult.adapt)
    );
  }

  createPlant(plant: any): Observable<Plant> {
    const plantUrl = `${this._privateApi}/plants`;
    return this.http.post<any>(plantUrl, plant).pipe(tap(response => console.log(response)));
  }


  updatePlantWithTaxonomy(plantId: number, plant: Plant): Observable<Plant> {
    const plantUrl = `${this._privateApi}/plants/${plantId}/taxonomy`;
    return this.http.put<any>(plantUrl, plant).pipe(
      map(Plant.adapt)
    );
  }

  deletePlant(plantId: number): Observable<any> {
    const plantUrl = `${this._privateApi}/plants/${plantId}`;
    return this.http.delete(plantUrl);

  }

  activate(plantId: number): Observable<any> {
    const plantUrl = `${this._privateApi}/plants/${plantId}/activate`;
    return this.http.put(plantUrl, {} as JSON);

  }

  readPlantWithTaxonomy(plantId: number) {
    return this.http.get<Plant>(`${this._privateApi}/plants/${plantId}/taxonomy`).pipe(
      map(Plant.adapt)
    );
  }

  initiateTranslations(id: number) {
    const plantUrl = `${this._privateApi}/plants/${id}/initiate`;
    return this.http.put(plantUrl, {} as JSON);
  }

  changeTranslations(id: number, plant: Plant, sourceLanguage: string, targetLanguage: string, contextHasChanged: string): Observable<any> {
    const translationsUrl = `${this._privateApi}/plants/${id}/translations`;

    return this.http.put<any>(translationsUrl, {
      ...plant,
      sourceLanguage,
      targetLanguage,
      contextHasChanged
    }).pipe();
  }

  retrievePlantsForDropDown(): Observable<Plant[]>  {
    const plantUrl = `${this._privateApi}/plants/dropdown`;
    return this.http.get<any>(plantUrl).pipe(
      map(response => response.content.map(Plant.adapt))
    );
  }
}

export class PlantSearchParameters {
  constructor(
    public plantCode: string = '',
    public name: string = '',
    public genusId: number = null,
    public category: string = '',
    public lifetime: string = '',
    public plantType: string = '',
    public flowerMonths: string[] = [],
    public colors: string[] = [],
    public special: boolean = null,
    public improved: boolean = null,
    public newPlant: boolean = null,
    public active: boolean = null,
    public page: number = null,
    public size: number = null,
    public sortAlias: string = '',
    public direction: number = 0,
    public sourceLanguage: string = '',
    public targetLanguage: string = '',
    public sortBy: string = '',
    public selectedCultivarNameEn: string = '',
    public selectedcultivarId: number = null,
    public specieBotanicName: string = '',
    public specieId: number = null,
    public genusBotanicName: string = '',
    public productType: string = '',
    public actions: string[] = [],
    public familyBotanicName: string = ''
  ) {
  }

  build(): HttpParams {
    let params = new HttpParams();

    if (this.name) { params = params.append('nameEn', this.name); }
    if (this.plantCode) { params = params.append('plantCode', this.plantCode); }
    if (this.sourceLanguage) { params = params.append('sourceLanguage', this.sourceLanguage); }
    if (this.targetLanguage) { params = params.append('targetLanguage', this.targetLanguage); }
    if (this.genusId) { params = params.append('genusId' , String(this.genusId)); }
    if (this.specieId) { params = params.append('specieId' , String(this.specieId)); }
    if (this.selectedcultivarId) { params = params.append('selectedCultivarId' , String(this.selectedcultivarId)); }
    if (this.category) { params = params.append('category' , this.category); }
    if (this.lifetime) { params = params.append('lifetime' , this.lifetime); }
    if (this.actions && this.actions.length !== 0) { params = params.append('actions' ,  this.actions.join(',')); }
    if (this.flowerMonths && this.flowerMonths.length !== 0) { params = params.append('flowerMonths' , this.flowerMonths.join(',')); }
    if (this.colors && this.colors.length !== 0) { params = params.append('colors' , this.colors.join(',')); }
    if (this.plantType) { params = params.append('PlantType' , this.plantType); }
    if (this.special !== false && this.special !== null) { params = params.append('specialPlant' , String(true)); }
    if (this.active !== null) { params = params.append('active' , String(this.active)); }
    if (this.improved !== false && this.improved !== null) { params = params.append('improvedPlant' , String(true)); }
    if (this.newPlant !== false && this.newPlant !== null) { params = params.append('newPlant' , String(true)); }
    if (this.page) { params = params.append('page' , String(this.page - 1)); }
    if (this.size) { params = params.append('size' , String(this.size)); }
    if (this.direction !== 0 && this.sortBy) { params = params.append('sort' , `${this.sortBy},${this.direction === -1 ? 'desc' : 'asc'}`); }
    if (this.genusBotanicName) { params = params.append('genusBotanicName', this.genusBotanicName); }
    if (this.familyBotanicName) { params = params.append('familyBotanicName', this.familyBotanicName); }
    if (this.specieBotanicName) { params = params.append('specieBotanicName', this.specieBotanicName); }
    if (this.selectedCultivarNameEn) { params = params.append('selectedCultivarNameEn', this.selectedCultivarNameEn); }

    return params;
  }
}
