import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {text} from 'node:stream/consumers';

@Component({
  selector: 'app-taxonomy-translations',
  templateUrl: './taxonomy-translations.component.html',
  styleUrls: ['./taxonomy-translations.component.css']
})
export class TaxonomyTranslationsComponent implements OnInit {
  get langs(): ['NL', 'FR', 'EN', 'DE'] {
    return this._langs;
  }
  @Input() form: FormGroup;
  @Input() editMode: false;

  translateCriteria = new FormGroup({
    translatableDescription: new FormControl(),
    sourceLanguage: new FormControl('EN')
  });
  reloadSuggestions = new EventEmitter<string>();
  private _langs: ['NL', 'FR', 'EN', 'DE'];
  radioValue1: any;


  constructor() { }

  ngOnInit(): void {
    this.translateCriteria.controls.translatableDescription.valueChanges.subscribe(text => {
      this.reloadSuggestions.next(text);
    });

    this.translateCriteria.controls.sourceLanguage.valueChanges.subscribe(language => {
      this.reloadSuggestions.next(this.translateCriteria.controls.translatableDescription.value);
    });
  }

}
