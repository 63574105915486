<p-card header="Vertalingen">
  <div class="grid">


    <div class="col-12" [formGroup]="form">
      <div class="mb-3" *ngIf="editMode">
        <p-checkbox binary="true" class="col-12 p-md-4 lg:col-4 tw-mt-8 m-1" label="Nederlandse vertaling nodig" formControlName="NlTranslationNeeded"></p-checkbox>
        <p-checkbox binary="true" class="col-12 p-md-4 lg:col-4 tw-mt-8 m-1"  label="Franse vertaling nodig" formControlName="FRTranslationNeeded"></p-checkbox>
        <p-checkbox binary="true" class="col-12 p-md-4 lg:col-4 tw-mt-8 m-1" label="Duitse vertaling nodig" formControlName="DETranslationNeeded"></p-checkbox>
        <p-checkbox binary="true" class="col-12 p-md-4 lg:col-4 tw-mt-8 m-1" label="Engelse vertaling nodig" formControlName="ENTranslationNeeded"></p-checkbox>
        <p-checkbox binary="true" class="col-12 p-md-4 lg:col-4 tw-mt-8 m-1" label="Poolse vertaling nodig" formControlName="PLTranslationNeeded"></p-checkbox>
        <p-checkbox binary="true" class="col-12 p-md-4 lg:col-4 tw-mt-8 m-1" label="Hongaarse vertaling nodig" formControlName="HUTranslationNeeded"></p-checkbox>

      </div>
      <div [formGroup]="translateCriteria">
        <div class="flex flex-column mb-5">
          <div class="flex flex-wrap">
            <p-radioButton name="sourceLanguage" value="NL" formControlName="sourceLanguage" label="NL"></p-radioButton>
            <p-radioButton name="sourceLanguage" value="EN" formControlName="sourceLanguage" label="EN"></p-radioButton>
            <p-radioButton name="sourceLanguage" value="FR" formControlName="sourceLanguage" label="FR"></p-radioButton>
            <p-radioButton name="sourceLanguage" value="DE" formControlName="sourceLanguage" label="DE"></p-radioButton>
            <p-radioButton name="sourceLanguage" value="U" formControlName="sourceLanguage" label="U"></p-radioButton>
            <p-radioButton name="sourceLanguage" value="PL" formControlName="sourceLanguage" label="PL"></p-radioButton>
          </div>
          <p-floatLabel class="w-full mt-3">
            <textarea pInputTextarea rows="5" formControlName="translatableDescription"></textarea>
            <label for="nameNl">Te vertalen text</label>
          </p-floatLabel>
        </div>
      </div>
      <p-accordion>
        <p-accordionTab header="{{'common.card.translations.nl' | translate }}" [selected]="true">
          <div class="grid">
            <p-floatLabel class="m-5 w-full">
              <input pInputText id="nameNl" formControlName="nameNl"/>
              <label for="nameNl">{{ 'common.card.translations.name' | translate }}</label>
            </p-floatLabel>
          </div>
          <div class="grid">
            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" cols="1" formControlName="genericDescriptionNl"
                        id="genericDescriptionNl">
              </textarea>
              <label for="genericDescriptionNl">{{ 'common.card.translations.description.general' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" cols="10"  formControlName="seedsDescriptionNl"
                        id="seedsDescriptionNl">
              </textarea>
              <label for="seedsDescriptionNl">{{ 'common.card.translations.description.seeds' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" cols="10" formControlName="cuttingsDescriptionNl"
                        id="cuttingsDescriptionNl">
              </textarea>
              <label for="cuttingsDescriptionNl">{{ 'common.card.translations.description.cuttings' | translate }}</label>
            </p-floatLabel>

          </div>
          <p>Voorgestelde vertaling naar het nederlands</p>
          <app-suggestion
            id="nl_suggestion"
            class="col-12 p-md-4"
            [sourceLanguage]="translateCriteria.controls.sourceLanguage.value"
            targetLanguage="NL"
            [expanded]="true"
            [disableSelect]="true"
            [change]="reloadSuggestions"
          ></app-suggestion>
        </p-accordionTab>
        <p-accordionTab header="{{'common.card.translations.en' | translate }}" *ngIf="editMode">
          <div class="grid">

            <p-floatLabel class="m-5 w-full">
              <input pInputText id="nameEn" formControlName="nameNl"/>
              <label for="nameNl">{{ 'common.card.translations.name' | translate }}</label>
            </p-floatLabel>

          </div>
          <div class="grid">
            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5"  formControlName="genericDescriptionEn"
                        id="genericDescriptionEn">
              </textarea>
              <label for="genericDescriptionEn">{{ 'common.card.translations.description.general' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5"  formControlName="seedsDescriptionEn"
                        id="seedsDescriptionEn">
              </textarea>
              <label for="seedsDescriptionEn">{{ 'common.card.translations.description.seeds' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="cuttingsDescriptionEn"
                        id="cuttingsDescriptionEn">
              </textarea>
              <label for="cuttingsDescriptionEn">{{ 'common.card.translations.description.cuttings' | translate }}</label>
            </p-floatLabel>

          </div>
          <app-suggestion
            class="col-12 p-md-4"
            [sourceLanguage]="translateCriteria.controls.sourceLanguage.value"
            targetLanguage="EN"
            [expanded]="true"
            [disableSelect]="true"
            [change]="reloadSuggestions"
          ></app-suggestion>
        </p-accordionTab>
        <p-accordionTab header="{{'common.card.translations.fr' | translate }}" *ngIf="editMode">
          <div class="grid">

            <p-floatLabel class="m-5 w-full">
              <input pInputText id="nameFr" formControlName="nameFr"/>
              <label for="nameFr">{{ 'common.card.translations.name' | translate }}</label>
            </p-floatLabel>

          </div>
          <div class="grid">

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="genericDescriptionFr"
                        id="genericDescriptionFr">
              </textarea>
              <label for="genericDescriptionFr">{{ 'common.card.translations.description.general' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="seedsDescriptionFr"
                        id="seedsDescriptionFr">
              </textarea>
              <label for="seedsDescriptionFr">{{ 'common.card.translations.description.seeds' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="cuttingsDescriptionFr"
                        id="cuttingsDescriptionFr">
              </textarea>
              <label for="cuttingsDescriptionFr">{{ 'common.card.translations.description.cuttings' | translate }}</label>
            </p-floatLabel>

          </div>
          <app-suggestion
            class="col-12 p-md-4"
            [sourceLanguage]="translateCriteria.controls.sourceLanguage.value"
            targetLanguage="FR"
            [expanded]="true"
            [disableSelect]="true"
            [change]="reloadSuggestions"
          ></app-suggestion>
        </p-accordionTab>
        <p-accordionTab header="{{'common.card.translations.de' | translate }}" *ngIf="editMode">
          <div class="grid">

            <p-floatLabel class="m-5 w-full">
              <input pInputText id="nameDe" formControlName="nameDe"/>
              <label for="nameDe">{{ 'common.card.translations.name' | translate }}</label>
            </p-floatLabel>

          </div>
          <div class="grid">

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="genericDescriptionDe"
                        id="genericDescriptionDe">
              </textarea>
              <label for="genericDescriptionDe">{{ 'common.card.translations.description.general' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="seedsDescriptionDe"
                        id="seedsDescriptionDe">
              </textarea>
              <label for="seedsDescriptionDe">{{ 'common.card.translations.description.seeds' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="cuttingsDescriptionDe"
                        id="cuttingsDescriptionDe">
              </textarea>
              <label for="cuttingsDescriptionDe">{{ 'common.card.translations.description.cuttings' | translate }}</label>
            </p-floatLabel>

          </div>
          <app-suggestion
            class="col-12 p-md-4"
            [sourceLanguage]="translateCriteria.controls.sourceLanguage.value"
            targetLanguage="DE"
            [expanded]="true"
            [disableSelect]="true"
            [change]="reloadSuggestions"
          ></app-suggestion>
        </p-accordionTab>
        <p-accordionTab header="{{'common.card.translations.hu' | translate }}" *ngIf="editMode">
          <div class="grid">

            <p-floatLabel class="m-5 w-full">
              <input pInputText id="nameHu" formControlName="nameHu"/>
              <label for="nameHu">{{ 'common.card.translations.name' | translate }}</label>
            </p-floatLabel>

          </div>
          <div class="grid">


            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="genericDescriptionHu"
                        id="genericDescriptionHu">
              </textarea>
              <label for="genericDescriptionHu">{{ 'common.card.translations.description.general' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="seedsDescriptionHu"
                        id="seedsDescriptionHu">
              </textarea>
              <label for="seedsDescriptionHu">{{ 'common.card.translations.description.seeds' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="cuttingsDescriptionHu"
                        id="cuttingsDescriptionHu">
              </textarea>
              <label for="cuttingsDescriptionHu">{{ 'common.card.translations.description.cuttings' | translate }}</label>
            </p-floatLabel>

          </div>
        </p-accordionTab>
        <p-accordionTab header="{{'common.card.translations.pl' | translate }}" *ngIf="editMode">
          <div class="grid">

            <p-floatLabel class="m-5 w-full">
              <input pInputText id="namePl" formControlName="namePl"/>
              <label for="namePl">{{ 'common.card.translations.name' | translate }}</label>
            </p-floatLabel>

          </div>
          <div class="grid">

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="genericDescriptionPl"
                        id="genericDescriptionPl">
              </textarea>
              <label for="genericDescriptionPl">{{ 'common.card.translations.description.general' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="seedsDescriptionPl"
                        id="seedsDescriptionPl">
              </textarea>
              <label for="seedsDescriptionPl">{{ 'common.card.translations.description.seeds' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="m-5 w-full">
              <textarea pInputTextarea rows="5" formControlName="cuttingsDescriptionPl"
                        id="cuttingsDescriptionPl">
              </textarea>
              <label for="cuttingsDescriptionPl">{{ 'common.card.translations.description.cuttings' | translate }}</label>
            </p-floatLabel>

          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</p-card>


