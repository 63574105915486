<div class="layout-wrapper" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
          <p-messages></p-messages>

          <p-breadcrumb class="max-w-full m-5" [model]="breadcrumbService.items()">
            <ng-template pTemplate="item" let-item>
              <ng-container *ngIf="item.route; else elseBlock">
                <a [routerLink]="item.route" class="p-menuitem-link">
                  <span [ngClass]="[item.icon ? item.icon : '', 'text-color']"></span>
                  <span class="text-primary font-semibold">{{ item.label }}</span>
                </a>
              </ng-container>
              <ng-template #elseBlock>
                <a [href]="item.url">
                  <span class="text-color">{{ item.label }}</span>
                </a>
              </ng-template>
            </ng-template>
          </p-breadcrumb>

          <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
</div>
