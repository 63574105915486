import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AppConfigService} from '../providers/app-config.service';
import {Page} from '@shared/model/page';
import {Plant} from '@shared/model/plant';
import {ImportResult} from '@shared/model/import-result';
import {ProductImportUploadParameters} from '@core/http/product.service';
import {Selectedcultivar} from '@shared/model/selectedcultivar';
import {BasicPlant} from '@shared/model/basicPlant';

@Injectable({
  providedIn: 'root'
})
export class PlantService {
  private readonly _privateApi: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this._privateApi = this.appConfig.config().privateApiBase;
  }

  public fetchPagedPlantList(searchParameters: PlantSearchParameters): Observable<Page<Plant>> {
    const params = searchParameters.build();
    return this.http.get<Page<Plant>>(`${this._privateApi}/plants/paged`, { params });
  }

  public fetchComplexPagedPlantList(searchParameters: PlantSearchParameters): Observable<Page<Plant>> {
    // Convert the parameters to HttpParams
    let params = new HttpParams()
      .set('page', searchParameters.page?.toString() || '0')
      .set('size', searchParameters.size?.toString() || '10');

    if (searchParameters.sortBy) {
      params = params.set('sort', searchParameters.sortBy);
    }

    // Add filter parameters using dot notation
    const filterFields = ['plantCode', 'familyBotanicName', 'genusBotanicName',
      'specieBotanicName', 'selectedCultivarNameEn', 'nameEn'];

    filterFields.forEach(field => {
      const filterCriteria = searchParameters[field];
      if (filterCriteria?.value) {
        params = params
          .set(`${field}.value`, filterCriteria.value)
          .set(`${field}.matchMode`, filterCriteria.matchMode);
      }
    });

    return this.http.get<Page<Plant>>(`${this._privateApi}/plants/complex`, { params });
  }

  public findById(id: number): Observable<Plant> {
    return this.http.get<Plant>(`${this._privateApi}/plants/${id}`).pipe(
      map(Plant.adapt)
    );
  }

  updatePlant(plantId: number, plant: Plant): Observable<Plant> {
    const plantUrl = `${this._privateApi}/plants/${plantId}`;
    return this.http.put<any>(plantUrl, plant).pipe(
      map(Plant.adapt)
    );
  }

  uploadPlantList(parameters: ProductImportUploadParameters, file: File): Observable<ImportResult> {
    const data = new FormData();

    data.append('excelfile', file);

    return this.http.post<ImportResult>(`${this._privateApi}/import/excel/plant`, data).pipe(
      map(ImportResult.adapt)
    );
  }

  createPlant(plant: any): Observable<Plant> {
    const plantUrl = `${this._privateApi}/plants`;
    return this.http.post<any>(plantUrl, plant).pipe(tap(response => console.log(response)));
  }


  updatePlantWithTaxonomy(plantId: number, plant: Plant): Observable<Plant> {
    const plantUrl = `${this._privateApi}/plants/${plantId}/taxonomy`;
    return this.http.put<any>(plantUrl, plant).pipe(
      map(Plant.adapt)
    );
  }

  deletePlant(plantId: number): Observable<any> {
    const plantUrl = `${this._privateApi}/plants/${plantId}`;
    return this.http.delete(plantUrl);

  }

  activate(plantId: number): Observable<any> {
    const plantUrl = `${this._privateApi}/plants/${plantId}/activate`;
    return this.http.put(plantUrl, {} as JSON);

  }

  readPlantWithTaxonomy(plantId: number) {
    return this.http.get<Plant>(`${this._privateApi}/plants/${plantId}/taxonomy`).pipe(
      map(Plant.adapt)
    );
  }

  initiateTranslations(id: number) {
    const plantUrl = `${this._privateApi}/plants/${id}/initiate`;
    return this.http.put(plantUrl, {} as JSON);
  }

  changeTranslations(id: number, plant: Plant, sourceLanguage: string, targetLanguage: string, contextHasChanged: string): Observable<any> {
    const translationsUrl = `${this._privateApi}/plants/${id}/translations`;

    return this.http.put<any>(translationsUrl, {
      ...plant,
      sourceLanguage,
      targetLanguage,
      contextHasChanged
    }).pipe();
  }

  retrievePlantsForDropDown(): Observable<Plant[]>  {
    const plantUrl = `${this._privateApi}/plants/dropdown`;
    return this.http.get<any>(plantUrl).pipe(
      map(response => response.content.map(Plant.adapt))
    );
  }

    fetchPlantsForSC(selectedcultivarId: number): Observable<BasicPlant[]>  {
      const plantUrl = `${this._privateApi}/plants/sc/${selectedcultivarId}`;
      return this.http.get<any>(plantUrl).pipe(
        map(response => response.content.map(BasicPlant.adapt))
      );
    }
}

export interface FilterCriteria {
  value: any;
  matchMode: string;
}

export class PlantSearchParameters {
  constructor(
    public plantCode: FilterCriteria | null = null,
    public nameEn: FilterCriteria | null = null,
    public familyBotanicName: FilterCriteria | null = null,
    public genusBotanicName: FilterCriteria | null = null,
    public specieBotanicName: FilterCriteria | null = null,
    public selectedCultivarNameEn: FilterCriteria | null = null,
    // Other non-filter parameters remain as before
    public genusId: number = null,
    public category: string = '',
    public lifetime: string = '',
    public plantType: string = '',
    public flowerMonths: string[] = [],
    public colors: string[] = [],
    public special: boolean = null,
    public improved: boolean = null,
    public newPlant: boolean = null,
    public active: boolean = null,
    public page: number = null,
    public size: number = null,
    public sortAlias: string = '',
    public direction: number = 0,
    public sourceLanguage: string = '',
    public targetLanguage: string = '',
    public sortBy: string = '',
    public selectedcultivarId: number = null,
    public specieId: number = null,
    public productType: string = '',
    public actions: string[] = [],
  ) {
  }

  build(): HttpParams {
    let params = new HttpParams();

    // Add pagination parameters
    if (this.page !== null) {
      params = params.set('page', String(this.page));
    }
    if (this.size !== null) {
      params = params.set('size', String(this.size));
    }

    // Add sort parameters
    if (this.sortBy && this.direction !== null) {
      params = params.set('sort', `${this.sortBy},${this.direction === 1 ? 'desc' : 'asc'}`);
    }

    // Add filter parameters
    const filterFields = ['plantCode', 'familyBotanicName', 'genusBotanicName',
      'specieBotanicName', 'selectedCultivarNameEn', 'nameEn'];

    filterFields.forEach(field => {
      const filterCriteria = this[field];
      if (filterCriteria?.value) {
        params = params
          .set(`${field}.value`, filterCriteria.value)
          .set(`${field}.matchMode`, filterCriteria.matchMode);
      }
    });

    // Add other non-filter parameters if they have values
    if (this.genusId) { params = params.set('genusId', String(this.genusId)); }
    if (this.specieId) { params = params.set('specieId', String(this.specieId)); }
    if (this.selectedcultivarId) { params = params.set('selectedCultivarId', String(this.selectedcultivarId)); }
    if (this.category) { params = params.set('category', this.category); }
    if (this.lifetime) { params = params.set('lifetime', this.lifetime); }
    if (this.plantType) { params = params.set('plantType', this.plantType); }
    if (this.flowerMonths?.length) { params = params.set('flowerMonths', this.flowerMonths.join(',')); }
    if (this.colors?.length) { params = params.set('colors', this.colors.join(',')); }
    if (this.special !== null) { params = params.set('specialPlant', String(this.special)); }
    if (this.improved !== null) { params = params.set('improvedPlant', String(this.improved)); }
    if (this.newPlant !== null) { params = params.set('newPlant', String(this.newPlant)); }
    if (this.active !== null) { params = params.set('active', String(this.active)); }
    if (this.actions?.length) { params = params.set('actions', this.actions.join(',')); }

    return params;
  }
}
