import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SidebarService} from '@core/services/sidebar.service';
import {MessageService} from 'primeng/api';
import {OverlayModule} from '@angular/cdk/overlay';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    OverlayModule,
    MessageService,
    SidebarService,
  ],
})
export class CoreModule { }
