import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControlDirective} from '@shared/directives/abstract-control.directive';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html'
})
export class DropdownComponent extends AbstractControlDirective {

  @Input('options') _options: any[];
  @Input() placeholder: string = null;
  @Input() showClear = false;
  @Input() optionLabel: (option: any) => string;
  @Input() optionValue: (option: any) => any = this.defaultValue;

  @Output() onDropdownValueChanged: EventEmitter<number> = new EventEmitter<number>();

  get options() {
    if (this.optionLabel) {
      this._options.map(option => {
        option.label = this.optionLabel(option);
        option.value = this.optionValue(option);
        return option;
      });
    }

    return this._options;
  }

  defaultValue(option: any): any {
    return option?.id;
  }

  onDropdownChange($event: any) {
    this.onDropdownValueChanged.emit($event.value);
  }
}
