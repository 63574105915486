import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'div[app-product-potsize-icon]',
  templateUrl: './product-potsize-icon.component.html',
  styleUrls: ['./product-potsize-icon.component.css']
})
export class ProductPotsizeIconComponent implements OnInit {

  @Input() fromSize: number;
  @Input() tillSize: number;

  constructor() { }

  ngOnInit(): void {
  }

  getSize(): string {
    if (this.tillSize == null || this.tillSize == 0) {
      return this.fromSize + ' cm';
    } else {
      return this.fromSize + ' - ' + this.tillSize + ' cm';
    }
  }

}
