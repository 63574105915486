import {Language} from '@shared/model/language';

export enum ProductType {
  SEEDS = 'SEEDS',
  SEEDLINGS = 'SEEDLINGS',
  CUTTINGS = 'CUTTINGS',
  TISSUECULTURE = 'TISSUECULTURE',
  SPLIT = 'SPLIT',
  POWERPLUG = 'POWERPLUG',
  NONE = 'NONE'
}

export const productTypeLabel: Record<Language, Record<ProductType, string>> = {
  [Language.EN]: {
    [ProductType.SEEDS]: 'Seeds',
    [ProductType.SEEDLINGS]: 'Seedlings',
    [ProductType.CUTTINGS]: 'Cuttings',
    [ProductType.TISSUECULTURE]: 'Tissue Culture',
    [ProductType.SPLIT]: 'Split',
    [ProductType.POWERPLUG]: 'Powerplug',
    [ProductType.NONE]: 'None'
  },
  [Language.NL]: {
    [ProductType.SEEDS]: 'Zaden',
    [ProductType.SEEDLINGS]: 'Zaailingen',
    [ProductType.CUTTINGS]: 'Stekken',
    [ProductType.TISSUECULTURE]: 'Weefselkweek',
    [ProductType.SPLIT]: 'Splitsen',
    [ProductType.POWERPLUG]: 'Powerplug',
    [ProductType.NONE]: 'Geen'
  },
  [Language.FR]: {
    [ProductType.SEEDS]: 'Graines',
    [ProductType.SEEDLINGS]: 'Semis',
    [ProductType.CUTTINGS]: 'Boutures',
    [ProductType.TISSUECULTURE]: 'Culture de Tissus',
    [ProductType.SPLIT]: 'Division',
    [ProductType.POWERPLUG]: 'Powerplug',
    [ProductType.NONE]: 'Aucun'
  },
  [Language.DE]: {
    [ProductType.SEEDS]: 'Samen',
    [ProductType.SEEDLINGS]: 'Setzlinge',
    [ProductType.CUTTINGS]: 'Stecklinge',
    [ProductType.TISSUECULTURE]: 'Gewebekultur',
    [ProductType.SPLIT]: 'Teilen',
    [ProductType.POWERPLUG]: 'Powerplug',
    [ProductType.NONE]: 'Keine'
  },
  [Language.PL]: {
    [ProductType.SEEDS]: 'Nasiona',
    [ProductType.SEEDLINGS]: 'Sadzonki',
    [ProductType.CUTTINGS]: 'Sadzonki z odcinków',
    [ProductType.TISSUECULTURE]: 'Kultura Tkankowa',
    [ProductType.SPLIT]: 'Podział',
    [ProductType.POWERPLUG]: 'Powerplug',
    [ProductType.NONE]: 'Brak'
  },
  [Language.HU]: {
    [ProductType.SEEDS]: 'Magvak',
    [ProductType.SEEDLINGS]: 'Palánták',
    [ProductType.CUTTINGS]: 'Dugványok',
    [ProductType.TISSUECULTURE]: 'Szövetkultúra',
    [ProductType.SPLIT]: 'Szétválasztás',
    [ProductType.POWERPLUG]: 'Powerplug',
    [ProductType.NONE]: 'Nincs'
  }
};
