export enum FruitColor {
  NONE = 'NONE' as any,
  GREEN = 'GREEN' as any,
  RED = 'RED' as any,
  YELLOW = 'YELLOW' as any,
  ORANGE = 'ORANGE' as any,
  ROSE = 'ROSE' as any,
  WHITE = 'WHITE' as any,
  PURPLE = 'PURPLE' as any,
  BLACK = 'BLACK' as any,
  BI_COLOR = 'BI_COLOR' as any,
  SPECIALS = 'SPECIALS' as any,
}
