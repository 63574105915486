import {Language} from '@shared/model/language';

export enum Country {
  belgium = 'belgium',
  netherlands = 'netherlands',
  france = 'france',
  uk = 'uk',
  germany = 'germany',
  belgium_north = 'belgium_north',
  belgium_south = 'belgium_south',
  ireland = 'ireland'
}

export const countryLabel: Record<Language, Record<Country, string>> = {
  [Language.EN]: {
    [Country.belgium]: 'Belgium',
    [Country.netherlands]: 'Netherlands',
    [Country.france]: 'France',
    [Country.uk]: 'UK',
    [Country.germany]: 'Germany',
    [Country.belgium_north]: 'Belgium North',
    [Country.belgium_south]: 'Belgium South',
    [Country.ireland]: 'Ireland'
  },
  [Language.NL]: {
    [Country.belgium]: 'België',
    [Country.netherlands]: 'Nederland',
    [Country.france]: 'Frankrijk',
    [Country.uk]: 'VK',
    [Country.germany]: 'Duitsland',
    [Country.belgium_north]: 'België Noord',
    [Country.belgium_south]: 'België Zuid',
    [Country.ireland]: 'Ierland'
  },
  [Language.FR]: {
    [Country.belgium]: 'Belgique',
    [Country.netherlands]: 'Pays-Bas',
    [Country.france]: 'France',
    [Country.uk]: 'Royaume-Uni',
    [Country.germany]: 'Allemagne',
    [Country.belgium_north]: 'Belgique Nord',
    [Country.belgium_south]: 'Belgique Sud',
    [Country.ireland]: 'Irlande'
  },
  [Language.DE]: {
    [Country.belgium]: 'Belgien',
    [Country.netherlands]: 'Niederlande',
    [Country.france]: 'Frankreich',
    [Country.uk]: 'Vereinigtes Königreich',
    [Country.germany]: 'Deutschland',
    [Country.belgium_north]: 'Belgien Nord',
    [Country.belgium_south]: 'Belgien Süd',
    [Country.ireland]: 'Irland'
  },
  [Language.PL]: {
    [Country.belgium]: 'Belgia',
    [Country.netherlands]: 'Holandia',
    [Country.france]: 'Francja',
    [Country.uk]: 'Wielka Brytania',
    [Country.germany]: 'Niemcy',
    [Country.belgium_north]: 'Belgia Północna',
    [Country.belgium_south]: 'Belgia Południowa',
    [Country.ireland]: 'Irlandia'
  },
  [Language.HU]: {
    [Country.belgium]: 'Belgium',
    [Country.netherlands]: 'Hollandia',
    [Country.france]: 'Franciaország',
    [Country.uk]: 'Egyesült Királyság',
    [Country.germany]: 'Németország',
    [Country.belgium_north]: 'Belgium Észak',
    [Country.belgium_south]: 'Belgium Dél',
    [Country.ireland]: 'Írország'
  }
};
