<app-input
  [label]="label"
  [control]="control">
  <p-multiSelect [class.col-8]="!isVertical()"
                 [formControl]="control"
                 [options]="options"
                 [defaultLabel]="placeholder"
                 [selectedItemsLabel]="selectedItemsLabel"
                 [emptyFilterMessage]="emptyFilterMessage"
                 [showHeader]="showHeader"
                 [filter]="filter"
                 [virtualScroll]="virtualScroll"
                 [style]="{'width':'100%'}"
                 (onChange)="onDropdownChange($event)">
  </p-multiSelect>
</app-input>
