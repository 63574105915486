<div class="border-1 surface-border border-round">
  <span class="text-900 font-bold block border-bottom-1 surface-border p-3">{{'taxonomy.audit.title' | translate}}</span>
  <div class="p-3">
    <div class="border-1 surface-border mb-2 flex justify-content-between align-items-center py-2 px-3 border-round">
      <span class="text-900 font-bold p-3">{{'taxonomy.audit.createdBy' | translate}}</span>
      {{auditable?.createdBy || '-'}}
    </div>
    <div class="border-1 surface-border mb-2 flex justify-content-between align-items-center py-2 px-3 border-round">
      <span class="text-900 font-bold p-3">{{'taxonomy.audit.createdOn' | translate}}</span>
      {{ isValidDate(auditable?.createdOn) ? getFormattedDate(auditable?.createdOn) : '-' }}
    </div>
    <div class="border-1 surface-border mb-2 flex justify-content-between align-items-center py-2 px-3 border-round">
      <span class="text-900 font-bold p-3">{{'taxonomy.audit.changedBy' | translate}}</span>
      {{auditable?.lastModifiedBy || '-'}}
    </div>
    <div class="border-1 surface-border mb-2 flex justify-content-between align-items-center py-2 px-3 border-round">
      <span class="text-900 font-bold p-3">{{'taxonomy.audit.changedOn' | translate}}</span>
      {{ isValidDate(auditable?.lastModifiedOn) ? getFormattedDate(auditable?.lastModifiedOn) : '-' }}
    </div>
  </div>
</div>
