export class DateParser {
  /**
   * Parses a date string in various formats, including European format (DD/MM/YYYY)
   * @param dateString The date string to parse
   * @returns A valid Date object or null
   */
  static parseDate(dateString: any): Date | null {
    if (!dateString) {
      return null;
    }

    // If it's already a valid Date object, return it
    if (dateString instanceof Date && !isNaN(dateString.getTime())) {
      return dateString;
    }

    // If it's not a string, we can't parse it
    if (typeof dateString !== 'string') {
      return null;
    }

    // Check for European format (DD/MM/YYYY)
    if (dateString.includes('/')) {
      try {
        const parts = dateString.split(' ')[0].split('/');
        if (parts.length === 3) {
          const day = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1; // JavaScript months are 0-indexed
          const year = parseInt(parts[2], 10);

          // Get time part if available
          let hours = 0, minutes = 0;
          if (dateString.includes(' ') && dateString.split(' ')[1]) {
            const timeParts = dateString.split(' ')[1].split(':');
            hours = parseInt(timeParts[0], 10);
            minutes = parseInt(timeParts[1], 10);
          }

          const date = new Date(year, month, day, hours, minutes);
          if (!isNaN(date.getTime())) {
            return date;
          }
        }
      } catch (e) {
        console.error('Error parsing European date format:', e);
      }
    }

    // Try standard date parsing as fallback
    try {
      const date = new Date(dateString);
      if (!isNaN(date.getTime())) {
        return date;
      }
    } catch (e) {
      console.error('Error parsing date:', e);
    }

    // Return null if all parsing attempts fail
    return null;
  }

  /**
   * Formats a date as a string in European format (DD/MM/YYYY HH:MM)
   * @param date The date to format
   * @returns A formatted date string or empty string if invalid
   */
  static formatDate(date: Date | string | null): string {
    if (!date) {
      return '';
    }

    let dateObj: Date;

    if (typeof date === 'string') {
      dateObj = this.parseDate(date);
      if (!dateObj) {
        return date; // Return original string if parsing fails
      }
    } else if (date instanceof Date) {
      dateObj = date;
    } else {
      return '';
    }

    if (isNaN(dateObj.getTime())) {
      return '';
    }

    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
}
