import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppConfigService} from '@core/providers/app-config.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Page} from '@shared/model/page';
import {Slide} from '@shared/model/slide';

@Injectable({
  providedIn: 'root'
})
export class SlideshowItemService {
  private readonly _privateApi: string;

  constructor(
    private http: HttpClient,
    private configProvider: AppConfigService
  ) {
    this._privateApi = this.configProvider.config().privateApiBase;
  }

  public readSlideshowItems(): Observable<Page<Slide>> {
    const slideshowItemsUrl = `${this.configProvider.config().privateApiBase}/slideshowitems`;
    return this.http.get<Page<Slide>>(slideshowItemsUrl).pipe(
      map(page => Page.adapt(page, page.content.map(Slide.adapt)))
    );
  }

  editSlideshowItem(slideshowItemId: number, slideshowItem: Slide): Observable<number> {
    const slideshowItemUrl = `${this.configProvider.config().privateApiBase}/slideshowitems/${slideshowItemId}`;

    return this.http.put<any>(slideshowItemUrl, slideshowItem).pipe(
      map(response => response.slideshowItemId)
    );
  }

  show(slideshowItemId: number): Observable<Slide> {
    const slideshowItemUrl = `${this.configProvider.config().privateApiBase}/slideshowitems/${slideshowItemId}`;
    return this.http.get<Slide>(slideshowItemUrl).pipe(
      map(Slide.adapt)
    );
  }

  create(slideshowItem: Slide): Observable<string> {
    const slideshowItemsUrl = `${this._privateApi}/slideshowitems`;
    return this.http.post<any>(slideshowItemsUrl, slideshowItem).pipe(
      tap(response => console.log(response))
    );
  }


  deleteSlide(slideId: number): Observable<any> {
    const slideshowItemsUrl = `${this._privateApi}/slideshowitems/${slideId}`;

    return this.http.delete(slideshowItemsUrl);

  }
}

export class SlideshowItemSearchParameters {
  constructor(
    public image: string = '',
    public title: string = '',
    public type: string = '',
    public raescode: string = '',
    public categoryId: string = '',
    public routerLink: string = '',
    public page: number = null,
    public size: number = null,
  ) {}

  build(): HttpParams {
    let params = new HttpParams();

    if (this.image) {
      params = params.append('image', this.image);
    }
    if (this.title) {
      params = params.append('title', this.title);
    }
    if (this.type) {
      params = params.append('type', this.type);
    }
    if (this.raescode) {
      params = params.append('raescode', this.raescode);
    }
    if (this.categoryId) {
      params = params.append('categoryId', this.categoryId);
    }
    if (this.routerLink) {
      params = params.append('routerLink', this.routerLink);
    }
    if (this.page) {
      params = params.append('page', String(this.page - 1));
    }
    if (this.size) {
      params = params.append('size', String(this.size));
    }

    return params;
  }
}
