import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(value: string): Date | null {
    if (!value || value.trim() === '') {
      return null;
    }

    const [day, month, yearHourMin] = value.split('/');
    const [year, hourMin] = yearHourMin.split(' ');
    const [hour, min] = hourMin.split(':');

    return new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(min));
  }

}
