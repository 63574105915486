import {appRoutesNames} from './app.routes.names';
import {digitalcatalogRoutesNames} from '@features/digitalcatalog/digitalcatalog.routes.names';
import {accountRoutesNames} from '@features/account/account.routes.names';
import {orderformRoutesNames} from '@features/orderform/orderform.routes.names';
import {jobRoutesNames} from '@features/job/job.routes.names';
import {newsitemRoutesNames} from '@features/newsitem/newsitem.routes.names';
import {contactinfoRoutesNames} from '@features/contactinfo/contactinfo.routes.names';
import {taxonomyRoutesNames} from '@features/taxonomy/taxonomy.routes.names';
import {availabilityRoutesNames} from '@features/availability/availability.routes.names';
import {userRoutesNames} from '@features/user/user.routes.names';
import {customerRoutesNames} from '@features/customer/customer.routes.names';
import {translationRoutesNames} from '@features/translation/translation.routes.names';
import {xmlcatalogRoutesNames} from '@features/xmlcatalog/xmlcatalog.routes.names';
import {bridgeRoutesNames} from '@features/bridge/bridge.routes.names';
import {photoRoutesNames} from '@features/photo/photo.routes.names';
import {printedCatalogRoutesNames} from '@features/printed-catalog/printed-catalog.routes.names';

export const appRoutesLinks = {

  HOME: [`/${appRoutesNames.HOME}`],
  LOGIN: [`/${appRoutesNames.LOGIN}`],
  ERROR: [`/${appRoutesNames.ERROR}`],
  ACCOUNT_PROFILE: [`/${appRoutesNames.ACCOUNT}/${accountRoutesNames.PROFILE}`],
  AVAILABILITY_OVERVIEW: [`/${appRoutesNames.AVAILABILITY}`],
  IMAGEDB_OVERVIEW: [`/${appRoutesNames.IMAGEDB}`],
  AVAILABILITY_UPLOAD: [`/${appRoutesNames.AVAILABILITY}/${availabilityRoutesNames.UPLOAD}`],
  XMLCATALOG_GENERATE: [`/${appRoutesNames.XMLCATALOG}/${xmlcatalogRoutesNames.GENERATE}`],
  DIGITALCATALOG_OVERVIEW: [`/${appRoutesNames.DIGITALCATALOG}`],
  PRINTEDCATALOG_OVERVIEW: [`/${appRoutesNames.PRINTEDCATALOG}`],
  DIGITALCATALOG_CREATE: [`/${appRoutesNames.DIGITALCATALOG}/${digitalcatalogRoutesNames.CREATE}`],
  PRINTEDCATALOG_CREATE: [`/${appRoutesNames.PRINTEDCATALOG}/${digitalcatalogRoutesNames.CREATE}`],
  DIGITALCATALOG_EDIT: (id) => [`/${appRoutesNames.DIGITALCATALOG}/${digitalcatalogRoutesNames.EDIT.replace(':id', id)}`],
  ORDERFORM_OVERVIEW: [`/${appRoutesNames.ORDERFORM}`],
  ORDERFORM_CREATE: [`/${appRoutesNames.ORDERFORM}/${orderformRoutesNames.CREATE}`],
  ORDERFORM_EDIT: (id) => [`/${appRoutesNames.ORDERFORM}/${orderformRoutesNames.EDIT.replace(':id', id)}`],
  PRINTEDCATALOG_EDIT: (id) => [`/${appRoutesNames.PRINTEDCATALOG}/${printedCatalogRoutesNames.EDIT.replace(':id', id)}`],
  PRINTEDCATALOG_PLANTS: (id) => [`/${appRoutesNames.PRINTEDCATALOG}/${id}/plants`],
  ORDER_OVERVIEW: [`/${appRoutesNames.ORDER}`],
  FLOWERPLAN_OVERVIEW: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.FLOWERPLANPRODUCT_OVERVIEW}`],
  FLOWERPLAN_PRODUCT_EDIT: (id) => [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.FLOWERPLANPRODUCT_EDIT.replace(':id', id)}`],
  FLOWERPLAN_PRODUCT_OVERVIEW: [`/${appRoutesNames.FLOWERPLAN}/${appRoutesNames.PRODUCT}`],
  FLOWERPLAN_CUSTOMER_OVERVIEW: [`/${appRoutesNames.FLOWERPLAN}/${appRoutesNames.CUSTOMER}`],
  FLOWERPLAN_AVAILABILITY_OVERVIEW: [`/${appRoutesNames.FLOWERPLAN}/${appRoutesNames.AVAILABILITY}`],
  CUSTOMER_OVERVIEW: [`/${appRoutesNames.CUSTOMER}`],
  CUSTOMER_EDIT: (id) => [`/${appRoutesNames.CUSTOMER}/${customerRoutesNames.EDIT.replace(':id', id)}`],
  REPRESENTATIVE_OVERVIEW: [`/${appRoutesNames.REPRESENTATIVE}`],
  REPRESENTATIVE_EDIT: (id) => [`/${appRoutesNames.REPRESENTATIVE_EDIT.replace(':id', id)}`],
  SLIDESHOW_OVERVIEW: [`/${appRoutesNames.SLIDESHOW}`],
  SLIDESHOW_EDIT: (id) => [`/${appRoutesNames.SLIDESHOW_EDIT.replace(':id', id)}`],
  SLIDESHOW_CREATE: [`/${appRoutesNames.SLIDESHOW_CREATE}`],
  REPRESENTATIVE_CREATE: [`/${appRoutesNames.REPRESENTATIVE_CREATE}`],
  TRANSLATION_DASHBOARD: [`/${appRoutesNames.TRANSLATION}/${translationRoutesNames.DASHBOARD}`],
  TRANSLATION_PLANT_OVERVIEW: [`/${appRoutesNames.TRANSLATION}/${translationRoutesNames.PLANT_OVERVIEW}`],
  TRANSLATION_PLANT_EDIT: (id) => [`/${appRoutesNames.TRANSLATION}/${translationRoutesNames.PLANT_EDIT.replace(':id', id)}`],
  TRANSLATION_PRODUCT_OVERVIEW: [`/${appRoutesNames.TRANSLATION}/${translationRoutesNames.PRODUCT_OVERVIEW}`],
  TRANSLATION_PRODUCT_EDIT: (id) => [`/${appRoutesNames.TRANSLATION}/${translationRoutesNames.PRODUCT_EDIT.replace(':id', id)}`],
  TRANSLATION_SPECIE_OVERVIEW: [`/${appRoutesNames.TRANSLATION}/${translationRoutesNames.SPECIE_OVERVIEW}`],
  TRANSLATION_SPECIE_EDIT: (id) => [`/${appRoutesNames.TRANSLATION}/${translationRoutesNames.SPECIE_EDIT.replace(':id', id)}`],
  TRANSLATION_GENUS_OVERVIEW: [`/${appRoutesNames.TRANSLATION}/${translationRoutesNames.GENUS_OVERVIEW}`],
  TRANSLATION_GENUS_EDIT: (id) => [`/${appRoutesNames.TRANSLATION}/${translationRoutesNames.GENUS_EDIT.replace(':id', id)}`],
  TRANSLATION_SELECTEDCULTIVAR_OVERVIEW: [`/${appRoutesNames.TRANSLATION}/${translationRoutesNames.SELECTEDCULTIVAR_OVERVIEW}`],
  TRANSLATION_SELECTEDCULTIVAR_EDIT: (id) => [`/${appRoutesNames.TRANSLATION}/${translationRoutesNames.SELECTEDCULTIVAR_EDIT.replace(':id', id)}`],
  USER_OVERVIEW: [`/${appRoutesNames.USER}`],
  USER_EDIT: (id) => [`/${appRoutesNames.USER}/${userRoutesNames.EDIT.replace(':id', id)}`],
  USER_CREATE: [`/${appRoutesNames.USER}/${userRoutesNames.CREATE}`],
  PHOTO_OVERVIEW: [`/${appRoutesNames.PHOTO}`],
  PHOTO_CREATE: [`/${appRoutesNames.PHOTO}/${photoRoutesNames.CREATE}`],
  PHOTO_EDIT: (id) => [`/${appRoutesNames.PHOTO}/${photoRoutesNames.EDIT.replace(':id', id)}`],
  JOB_OVERVIEW: [`/${appRoutesNames.JOB}`],
  JOBAPPLY_OVERVIEW: [`/${appRoutesNames.JOB}/${appRoutesNames.JOBAPPLY}`],
  JOB_CREATE: [`/${appRoutesNames.JOB}/${jobRoutesNames.CREATE}`],
  JOB_EDIT: (id) => [`/${appRoutesNames.JOB}/${jobRoutesNames.EDIT.replace(':id', id)}`],
  JOBAPPLY_EDIT: (id) => [`/${appRoutesNames.JOB}/${jobRoutesNames.JOBAPPLY_EDIT.replace(':id', id)}`],
  NEWSITEM_OVERVIEW: [`/${appRoutesNames.NEWSITEM}`],
  NEWSITEM_CREATE: [`/${appRoutesNames.NEWSITEM}/${newsitemRoutesNames.CREATE}`],
  NEWSITEM_EDIT: (id) => [`/${appRoutesNames.NEWSITEM}/${newsitemRoutesNames.EDIT.replace(':id', id)}`],
  CONTACTINFO_OVERVIEW: [`/${appRoutesNames.CONTACTINFO}`],
  CONTACTINFO_EDIT: (id) => [`/${appRoutesNames.CONTACTINFO}/${contactinfoRoutesNames.EDIT.replace(':id', id)}`],
  TAXONOMY_DASHBOARD: [`/${appRoutesNames.TAXONOMY}`],
  BRIDGE_OVERVIEW: [`/${appRoutesNames.BRIDGE}`],
  BRIDGE_PRODUCT_OVERVIEW: [`/${appRoutesNames.BRIDGE}/${bridgeRoutesNames.PRODUCT_OVERVIEW}`],
  BRIDGE_PRODUCT_LINKING: [`/${appRoutesNames.BRIDGE}/${bridgeRoutesNames.PRODUCT_LINKING}`],
  BRIDGE_PRODUCT_MATCHING: [`/${appRoutesNames.BRIDGE}/${bridgeRoutesNames.PRODUCT_MATCHING}`],
  BRIDGE_PRODUCT_PROPOSALS: [`/${appRoutesNames.BRIDGE}/${bridgeRoutesNames.PRODUCT_PROPOSALS}`],
  BRIDGE_PRODUCT_EDIT: (id) => [`/${appRoutesNames.BRIDGE}/${bridgeRoutesNames.PRODUCT_EDIT.replace(':id', id)}`],
  BRIDGE_ACTION_EDIT: (id) => [`/${appRoutesNames.BRIDGE}/${bridgeRoutesNames.ACTION_EDIT.replace(':id', id)}`],
  BRIDGE_ACTION_VIEW: (id) => [`/${appRoutesNames.BRIDGE}/${bridgeRoutesNames.ACTION_VIEW.replace(':id', id)}`],
  BRIDGE_CUSTOMER_OVERVIEW: [`/${appRoutesNames.BRIDGE}/${bridgeRoutesNames.CUSTOMER_OVERVIEW}`],
  BRIDGE_CUSTOMER_EDIT: (id) => [`/${appRoutesNames.BRIDGE}/${bridgeRoutesNames.CUSTOMER_EDIT.replace(':id', id)}`],
  BRIDGE_STOCK_OVERVIEW: [`/${appRoutesNames.BRIDGE}/${bridgeRoutesNames.STOCK_OVERVIEW}`],
  BRIDGE_STOCK_EDIT: (id) => [`/${appRoutesNames.BRIDGE}/${bridgeRoutesNames.STOCK_EDIT.replace(':id', id)}`],
  FAMILY_OVERVIEW: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.FAMILY_OVERVIEW}`],
  FAMILY_CREATE: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.FAMILY_CREATE}`],
  FAMILY_EDIT: (id) => [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.FAMILY_EDIT.replace(':id', id)}`],
  GENUS_OVERVIEW: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.GENUS_OVERVIEW}`],
  GENUS_CREATE: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.GENUS_CREATE}`],
  GENUS_EDIT: (id) => [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.GENUS_EDIT.replace(':id', id)}`],
  SPECIE_OVERVIEW: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.SPECIE_OVERVIEW}`],
  SPECIE_CREATE: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.SPECIE_CREATE}`],
  SPECIE_EDIT: (id) => [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.SPECIE_EDIT.replace(':id', id)}`],
  SELECTEDCULTIVAR_OVERVIEW: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.SELECTEDCULTIVAR_OVERVIEW}`],
  SELECTEDCULTIVAR_CREATE: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.SELECTEDCULTIVAR_CREATE}`],
  SELECTEDCULTIVAR_EDIT: (id) => [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.SELECTEDCULTIVAR_EDIT.replace(':id', id)}`],
  PRODUCT_OVERVIEW: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.PRODUCT_OVERVIEW}`],
  FLOWERPLANPRODUCT_OVERVIEW: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.FLOWERPLANPRODUCT_OVERVIEW}`],
  FLOWERPLANPRODUCT_EDIT: (id) => [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.FLOWERPLANPRODUCT_EDIT.replace(':id', ':id')}`],
  PRODUCT_CREATE: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.PRODUCT_CREATE}`],
  PRODUCT_EDIT: (id) => [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.PRODUCT_EDIT.replace(':id', id)}`],
  PLANT_OVERVIEW: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.PLANT_OVERVIEW}`],
  PLANT_CREATE: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.PLANT_CREATE}`],
  PLANT_EDIT: (id) => [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.PLANT_EDIT.replace(':id', id)}`],
  PLANT_PROPOSAL: (id) => [`/${appRoutesNames.BRIDGE}/${bridgeRoutesNames.PROPOSAL_EDIT.replace(':id', id)}`],
  PLANT_CATALOG: (id) => [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.PLANT_CATALOG.replace(':id', id)}`],
  PRODUCT_IMPORT: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.PRODUCT_IMPORT}`],
  PLANT_IMPORT: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.PLANT_IMPORT}`],
  SELECTEDCULTIVAR_IMPORT: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.SELECTEDCULTIVAR_IMPORT}`],
  PRODUCT_EXPORT: [`/${appRoutesNames.TAXONOMY}/${taxonomyRoutesNames.PRODUCT_EXPORT}`]
};
