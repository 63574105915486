export const appRoutesNames = {
  HOME: '',
  ERROR: 'error',
  LOGIN: 'login',
  ACCOUNT: 'account',
  CATALOG: 'catalog',
  AVAILABILITY: 'availability',
  DIGITALCATALOG: 'digitalcatalog',
  PRINTEDCATALOG: 'printedcatalog',
  XMLCATALOG: 'xmlcatalog',
  ORDERFORM: 'orderform',
  JOB: 'job',
  JOBAPPLY: 'jobapply',
  NEWSITEM: 'newsitem',
  CONTACTINFO: 'contactinfo',
  TAXONOMY: 'taxonomy',
  IMAGEDB: 'imagedb',
  USER: 'user',
  CUSTOMER: 'customer',
  PRODUCT: 'product',
  FLOWERPLAN: 'flowerplan',
  BRIDGE: 'bridge',
  REPRESENTATIVE: 'representative',
  REPRESENTATIVE_EDIT: 'representative/:id/edit',
  REPRESENTATIVE_CREATE: 'representative/create',
  SLIDESHOW: 'slideshow',
  SLIDESHOW_EDIT: 'slideshow/:id/edit',
  SLIDESHOW_CREATE: 'slideshow/create',
  LAYOUT: 'layout',
  TRANSLATION: 'translation',
  PHOTO: 'photo',
  ORDER: 'order'
};
