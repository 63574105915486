<form *ngIf="form" [formGroup]="form" novalidate>
  <div class="card">
    <span class="block text-900 font-bold text-xl mb-4">Slide</span>
    <div class="grid grid-nogutter flex-wrap gap-3 p-fluid">
      <div class="col-12 lg:col-8">
        <div class="grid formgrid">
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="title" formControlName="title"/>
              <label for="title">Titel (naam van de plant)</label>
            </p-floatLabel>
          </div>
          <div class="col-12 md:col-6 field">
            <p-floatLabel class="m-5">
              <p-dropdown
                [showClear]="true"
                inputId="plant"
                id="plant"
                optionValue="id"
                formControlName="plantId"
                placeholder="{{'slideshow.plant.name' | translate }}"
                [options]="plants"
                [optionLabel]="optionLabelPlant"
              >
              </p-dropdown>
              <label for="plant">{{ 'slideshow.plant.name' | translate }}</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="col-6 my-4">
              <p-dropdown formControlName="slideCategory"
                          [showClear]="true"
                          [checkmark]="true"
                          placeholder="{{'enum.slideCategory.title' | translate }}"
                          [options]="slideCategories"
                          id="slideCategory"
              ></p-dropdown>
              <label for="slideCategory">{{ 'enum.slideCategory.title' | translate }}</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="image" formControlName="image"/>
              <label for="image">Naam afbeelding (op S3)</label>
            </p-floatLabel>
          </div>
        </div>
      </div>

      <div class="flex-1 w-full lg:w-3 xl:w-4 flex flex-column row-gap-3 tw-mt-5">

        <div class="border-1 surface-border border-round">
          <span class="text-900 font-bold block border-bottom-1 surface-border p-3">Eigenschappen</span>
          <div class="p-3">
            <div class="border-1 surface-border flex justify-content-between align-items-center py-2 px-3 border-round">
              <label for="switch1">Actief</label>
              <p-inputSwitch formControlName="active" inputId="switch1" id="switch1"></p-inputSwitch>
            </div>
          </div>
        </div>

        <div class="flex flex-column sm:flex-row justify-content-between align-items-center gap-3 py-2">
          <button pButton pRipple class="p-button-danger flex-1 p-button-outlined"
                  label="{{'user.edit.details.cancelButton' | translate }}" icon="pi pi-fw pi-arrow-circle-left"
                  (click)="goToOverview()"></button>
          <button pButton pRipple class="p-button-primary flex-1 border-round"
                  label="{{'user.edit.details.createButton' | translate }}" icon="pi pi-fw pi-check"
                  (click)="submit()"></button>
        </div>
      </div>
    </div>
  </div>
</form>
