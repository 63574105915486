export class Soil {
  constructor(public id: string = '',
              public nameNl: string = '',
              public nameEn: string = '',
              public nameDe: string = '',
              public nameFr: string = '') {}
  static adapt(item: any): Soil {
    return new Soil(
      item.id,
      item.nameNl,
      item.nameEn,
      item.nameDe,
      item.nameFr
    );
  }
}
