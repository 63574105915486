import {Component, Input, OnInit} from '@angular/core';
import {Genus} from "@shared/model/genus";

@Component({
  selector: 'app-genus-icons',
  templateUrl: './genus-icons.component.html',
  styleUrls: ['./genus-icons.component.css']
})
export class GenusIconsComponent implements OnInit {
  @Input() genus: Genus;
  constructor() { }

  ngOnInit(): void {
  }

}
