import {Component, Input, OnInit} from '@angular/core';
import {Auditable} from '@shared/model/auditable';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html'
})
export class AuditComponent implements OnInit {

  constructor() { }
  @Input() auditable: Auditable;

  // Store the raw values in case we need to recover them
  private rawCreatedOn: any;
  private rawLastModifiedOn: any;

  ngOnInit(): void {
    console.log('Original auditable:', this.auditable);

    // Initialize auditable if it's undefined
    if (!this.auditable) {
      this.auditable = {
        createdBy: '',
        createdOn: null,
        lastModifiedBy: '',
        lastModifiedOn: null
      };
    } else {
      // Store the raw values before any processing
      this.rawCreatedOn = this.auditable.createdOn;
      this.rawLastModifiedOn = this.auditable.lastModifiedOn;

      // If we have invalid Date objects, try to fix them
      this.preprocessDates();
    }
  }

  /**
   * Preprocess dates to handle Invalid Date objects
   * This is needed because somewhere in your app, date strings might be
   * automatically converted to Invalid Date objects
   */
  private preprocessDates(): void {
    // Handle createdOn if it's an Invalid Date
    if (this.auditable.createdOn instanceof Date &&
      isNaN((this.auditable.createdOn as Date).getTime())) {
      console.log('Fixing invalid createdOn date');

      // Reset to null so our isValidDate and getFormattedDate can work
      this.auditable.createdOn = null;
    }

    // Handle lastModifiedOn if it's an Invalid Date
    if (this.auditable.lastModifiedOn instanceof Date &&
      isNaN((this.auditable.lastModifiedOn as Date).getTime())) {
      console.log('Fixing invalid lastModifiedOn date');

      // Reset to null
      this.auditable.lastModifiedOn = null;
    }
  }

  /**
   * Checks if a date is valid
   * @param date The date to check
   * @returns true if date is valid, false otherwise
   */
  isValidDate(date: any): boolean {
    if (!date) {
      // If we have rawCreatedOn or rawLastModifiedOn data, use it
      if (date === this.auditable.createdOn && this.rawCreatedOn) {
        return typeof this.rawCreatedOn === 'string' && this.rawCreatedOn.includes('/');
      }
      if (date === this.auditable.lastModifiedOn && this.rawLastModifiedOn) {
        return typeof this.rawLastModifiedOn === 'string' && this.rawLastModifiedOn.includes('/');
      }
      return false;
    }

    // Handle string dates in DD/MM/YYYY format
    if (typeof date === 'string' && date.includes('/')) {
      return true;
    }

    // Handle Date objects
    if (date instanceof Date) {
      return !isNaN(date.getTime());
    }

    return false;
  }

  /**
   * Gets a formatted date for display
   * @param date The date to format
   * @returns The formatted date string
   */
  getFormattedDate(date: any): string {
    // Check if we have raw data to use instead
    if (!date) {
      if (date === this.auditable.createdOn && this.rawCreatedOn &&
        typeof this.rawCreatedOn === 'string' && this.rawCreatedOn.includes('/')) {
        return this.formatEuropeanDateString(this.rawCreatedOn);
      }
      if (date === this.auditable.lastModifiedOn && this.rawLastModifiedOn &&
        typeof this.rawLastModifiedOn === 'string' && this.rawLastModifiedOn.includes('/')) {
        return this.formatEuropeanDateString(this.rawLastModifiedOn);
      }
      return '-';
    }

    // Handle string dates in DD/MM/YYYY format
    if (typeof date === 'string' && date.includes('/')) {
      return this.formatEuropeanDateString(date);
    }

    // Handle Date objects
    if (date instanceof Date && !isNaN(date.getTime())) {
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}, ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    }

    // Return original if we can't parse
    return typeof date === 'string' ? date : '-';
  }

  /**
   * Formats a European date string (DD/MM/YYYY HH:MM) for display
   * @param dateString The date string to format
   * @returns Formatted date string
   */
  private formatEuropeanDateString(dateString: string): string {
    try {
      const parts = dateString.split(' ')[0].split('/');
      if (parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10); // Keep as 1-indexed for display
        const year = parseInt(parts[2], 10);

        // Get time part if available
        let hours = 0, minutes = 0;
        if (dateString.includes(' ') && dateString.split(' ')[1]) {
          const timeParts = dateString.split(' ')[1].split(':');
          hours = parseInt(timeParts[0], 10);
          minutes = parseInt(timeParts[1], 10);
        }

        // Format as MM/DD/YYYY, HH:MM
        return `${month}/${day}/${year}, ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      }
    } catch (e) {
      console.error('Error parsing date:', e);
    }
    return dateString; // Return original if parsing fails
  }
}
