import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {CurrentUserService} from '@core/services/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard  {
  constructor(private currentUser: CurrentUserService,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const permissions = next.data.permissions;

    if (permissions === undefined) {
      this.router.navigate(['/error'], { queryParams: { code: 500} });
      throwError(() => new Error('Permissions can\'t be undefined when using PermissionGuard.'));
      return false;
    }

    return this.currentUser.hasAnyPermission$(permissions)
      .pipe(
        map(result => {
          if (result) {
            return true;
          }

          this.router.navigate(['/error'], { queryParams: { errorCode: 403 } });
          return false;
        })
      );
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const permissions = next.data.permissions;

    if (permissions === undefined) {
      this.router.navigate(['/error'], { queryParams: { code: 500} });
      throwError(() => new Error('Permissions can\'t be undefined when using PermissionGuard.'));
      return false;
    }

    return this.currentUser.hasAnyPermission$(permissions)
      .pipe(
        map(result => {
          if (result) {
            return true;
          }

          this.router.navigate(['/error'], { queryParams: { errorCode: 403 } });
          return false;
        })
      );
  }
}
