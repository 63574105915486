<app-input
  [label]="label"
  [control]="control"
>
  <textarea [class.col-8]="!isVertical()"
            [rows]="rows"
            [formControl]="control"
            [disabled]="disabled"
            pInputTextarea
            [autoResize]="autoResize"
  ></textarea>
</app-input>
