import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'div [app-product-icon]',
  templateUrl: './product-icon.component.html',
  styleUrls: ['./product-icon.component.css']
})
export class ProductIconComponent implements OnInit {

  @Input() productIcon: string;
  @Input() category: string;
  @Input() extension: string;

  constructor() { }

  ngOnInit(): void {
  }

  get icon(): string {
    return 'https://s3.eu-west-3.amazonaws.com/raes.cdn/icon/' + this.productIcon + '.' + this.extension;
  }

}
