<div class="grid">
  <div class="col-12">
    <div class="card px-6 py-6">
      <h5>Overzicht van de slides</h5>
      <p-toast></p-toast>

      <p-table #dt [value]="slides" [columns]="cols" responsiveLayout="scroll" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Toon {first} tot {last} van {totalRecords} items" [(selection)]="selectedRepresentative" selectionMode="single" [rowHover]="true" dataKey="id">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngSwitch]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns>
          <tr>
            <td>
              <span class="p-column-title">{{columns[0]}}</span>
              {{rowData.raescode}}
            </td>
            <td>
              <span class="p-column-title">{{columns[1]}}</span>
              {{rowData.title}}
            </td>
            <td>
              <span class="p-column-title">{{columns[2]}}</span>
              {{rowData.slideCategory}}
            </td>
            <td>
              <span class="p-column-title">Active</span>
              <i class="pi" [ngClass]="{ 'pi-check': rowData.active, 'pi-times': !rowData.active }" [style.color]="rowData.active ? 'green' : 'red'"></i>
            </td>
            <td>
              <div class="flex">
                <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="edit(rowData.id)" pTooltip="Slide aanpassen"></button>
                <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="delete(rowData.id, rowData.title)" pTooltip="Slide verwijderen"></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
  </div>
</div>
