import {Auditable} from '@shared/model/auditable';
import {DateParser} from '@shared/model/dateParser';

export class Slide implements Auditable {
  constructor(public id: number = 0,
              public image: string = '',
              public title: string  = '',
              public type: string  = '',
              public raescode: string  = '',
              public categoryId: string  = '',
              public plantId: string  = '',
              public slideCategory: string  = '',
              public active: boolean = false,
              public routerLink: string  = '',
              public lastModifiedBy: string = '',
              public lastModifiedOn: Date = null,
              public createdBy: string = '',
              public createdOn: Date = null) {}

  static adapt(item: any): Slide {
    return new Slide(
      item.id,
      item.image,
      item.title,
      item.type,
      item.raescode,
      item.categoryId,
      item.plantId,
      item.slideCategory,
      item.active,
      item.routerLink,
      item.lastModifiedBy,
      !!item.lastModifiedOn ? DateParser.parseDate(item.lastModifiedOn) : null,
      item.createdBy,
      !!item.createdOn ? DateParser.parseDate(item.createdOn) : null
    );
  }

}
