<div class="p-field p-fluid">
  <label>{{ label }}</label>
  <div class="p-inputgroup">
    <input [disabled]="true" [value]="result" *ngIf="!expanded" type="text" pInputText>
    <textarea [disabled]="true" [value]="result" *ngIf="expanded" pInputTextarea></textarea>
    <button *ngIf="!disableSelect" pButton pRipple label="{{'Overnemen' | translate }}" icon="pi pi-copy" class="p-button-help"
            (click)="onSelect.emit(result)"></button>
  </div>
  <div class="mt-2" *ngIf="showCopyButtons && result">
    <button pButton pRipple label="{{ 'Kopieer naar algemene beschrijving' | translate }}"
            icon="pi pi-copy" class="p-button-outlined p-button-sm mr-2 mb-2"
            (click)="onCopyToGeneric.emit(result)"></button>
    <button pButton pRipple label="{{ 'Kopieer naar zaden beschrijving' | translate }}"
            icon="pi pi-copy" class="p-button-outlined p-button-sm mr-2 mb-2"
            (click)="onCopyToSeeds.emit(result)"></button>
    <button pButton pRipple label="{{ 'Kopieer naar stekken beschrijving' | translate }}"
            icon="pi pi-copy" class="p-button-outlined p-button-sm mb-2"
            (click)="onCopyToCuttings.emit(result)"></button>
  </div>
</div>
