import {Category} from './category';
import {Usage} from './usage';
import {Stand} from './stand';
import {Lifetime} from './lifetime';
import {FlowerMonth} from './flowerMonth';
import {FruitMaturity} from './fruit-maturity';
import {FruitColor} from './fruit-color';
import {FruitShape} from './fruit-shape';
import {FlowerPeriod} from './flower-period';
import {FlowerPattern} from './flower-pattern';
import {PrimulaType} from './primula-type';
import {LeafType} from './leaf-type';
import {Fertilization} from './fertilization';
import {Spiciness} from './spiciness';
import {Trademark} from './trademark';
import {Soil} from './soil';
import {ProductType} from './product-type';
import {Disease} from './disease';
import {Color} from './color';
import {Auditable} from '@shared/model/auditable';
import {DateParser} from '@shared/model/dateParser';

export class Plant implements Auditable {
  constructor(public id: number = null,
              public active: boolean = false,
              public approvedNovelty: boolean = false,
              public autumnSelection: boolean = false,
              public reactivated: boolean = false,
              public category: Category = null,
              public colors: Color[] = [],
              public descriptionEn: string = '',
              public descriptionFr: string = '',
              public descriptionNl: string = '',
              public descriptionDe: string = '',
              public descriptionPl: string = '',
              public descriptionHu: string = '',
              public diseases: Disease[] = [],
              public earlyFlowering: boolean = false,
              public familyBotanicName: string = '',
              public fertilization: Fertilization = null,
              public fleuroselect: boolean = false,
              public floralAward: boolean = false,
              public flowerPattern: FlowerPattern = null,
              public flowerPeriod: FlowerPeriod = null,
              public flowerMonths: FlowerMonth = null,
              public fromHeight: number = 0,
              public fromPotSize: number = 0,
              public fromPottingPeriod: Date = null,
              public fromPottingTemperature: number = null,
              public fromSalesPeriod: Date = null,
              public fromSecondPottingPeriod: Date = null,
              public fromSecondSalesPeriod: Date = null,
              public fromSecondSowingPeriod: Date = null,
              public fromSowingPeriod: Date = null,
              public fromSowingTemperature: number = null,
              public fruitColor: FruitColor = null,
              public fruitMaturity: FruitMaturity = null,
              public fruitShape: FruitShape = null,
              public fullnameEn: string = '',
              public fullnameFr: string = '',
              public fullnameNl: string = '',
              public genusId: number = null,
              public genusBotanicName: string = '',
              public genusNameEn: string = '',
              public genusNameFr: string = '',
              public genusNameNl: string = '',
              public genusDescriptionEn: string = '',
              public genusDescriptionFr: string = '',
              public genusDescriptionNl: string = '',
              public geneticallyCompact: boolean = false,
              public hasSelectedCultivar: boolean = false,
              public height: number = null,
              public hybrid: boolean = false,
              public improvedPlant: boolean = false,
              public innovertOr: boolean = false,
              public innovertBronze: boolean = false,
              public ipmAward: boolean = false,
              public leafType: LeafType = null,
              public lifetimes: Lifetime[] = [],
              public lifetime: Lifetime = null,
              public nameEn: string = '',
              public nameFr: string = '',
              public nameNl: string = '',
              public nameDe: string = '',
              public namePl: string = '',
              public nameHu: string = '',
              public newPlant: boolean = false,
              public noImageAvailable: boolean = false,
              public primulaType: PrimulaType = null,
              public plantCode: string = '',
              public potSize: string = '',
              public productTypes: ProductType[] = [],
              public pottingTemperature: string = '',
              public pottingPeriod: string = '',
              public salesPeriod: string = '',
              public secondPottingPeriod: string = '',
              public secondSalesPeriod: string = '',
              public secondSowingPeriod: string = '',
              public selectedCultivarDescriptionEn: string = '',
              public selectedCultivarDescriptionFr: string = '',
              public selectedCultivarDescriptionNl: string = '',
              public selectedCultivarNameEn: string = '',
              public slug: string = '',
              public soils: Soil[] = [],
              public sowingPeriod: string = '',
              public sowingTemperature: string = '',
              public specialPlant: boolean = false,
              public specialSlug: string = '',
              public specieBotanicName: string = '',
              public specieDescriptionEn: string = '',
              public specieDescriptionFr: string = '',
              public specieDescriptionNl: string = '',
              public specieNameEn: string = '',
              public specieNameFr: string = '',
              public specieNameNl: string = '',
              public spiciness: Spiciness = null,
              public stands: Stand[] = [],
              public tillHeight: number = 0,
              public tillPotSize: number = 0,
              public tillPottingPeriod: Date = null,
              public tillPottingTemperature: number = null,
              public tillSalesPeriod: Date = null,
              public tillSecondPottingPeriod: Date = null,
              public tillSecondSalesPeriod: Date = null,
              public tillSecondSowingPeriod: Date = null,
              public tillSowingPeriod: Date = null,
              public tillSowingTemperature: number = null,
              public timing: string = '',
              public trademark: Trademark = null,
              public usages: Usage[] = [],
              public image: string = '',
              public selectedCultivarId: number = null,
              public specieId: number = null,
              public familyId: number = null ,
              public lastModifiedBy: string = '',
              public lastModifiedOn: Date = null,
              public createdBy: string = '',
              public createdOn: Date = null,
              public raesBreeding: boolean = false,
              public raesPowerplug: boolean = false,
              public overrideGenusProperties: boolean = false,
              public qrCode: string = ''
  ) {}


  static adapt(item: any): Plant {
    return new Plant(
      item.id,
      item.activePlant,
      item.approvedNovelty,
      item.autumnSelection,
      item.reactivated,
      item.category,
      item.colors,
      item.descriptionEn,
      item.descriptionFr,
      item.descriptionNl,
      item.descriptionDe,
      item.descriptionPl,
      item.descriptionHu,
      !!item.diseases ? item.diseases.map(Disease.adapt) : [],
      item.earlyFlowering,
      item.familyBotanicName,
      item.fertilization,
      item.fleuroselect,
      item.floralAward,
      item.flowerPattern,
      item.flowerPeriod,
      item.flowerMonths,
      item.fromHeight,
      item.fromPotSize,
      item.fromPottingPeriod,
      item.fromPottingTemperature,
      item.fromSalesPeriod,
      item.fromSecondPottingPeriod,
      item.fromSecondSalesPeriod,
      item.fromSecondSowingPeriod,
      item.fromSowingPeriod,
      item.fromSowingTemperature,
      item.fruitColor,
      item.fruitMaturity,
      item.fruitShape,
      item.fullnameEn,
      item.fullnameFr,
      item.fullnameNL,
      item.genusId,
      item.genusBotanicName,
      item.genusNameEn,
      item.genusNameFr,
      item.genusNameNl,
      item.genusDescriptionEn,
      item.genusDescriptionFr,
      item.genusDescriptionNl,
      item.geneticallyCompact,
      item.hasSelectedCultivar,
      item.height,
      item.hybrid,
      item.improvedPlant,
      item.innovertOr,
      item.innovertBronze,
      item.ipmAward,
      item.leafType,
      item.lifetimes,
      item.lifetime,
      item.nameEn,
      item.nameFr,
      item.nameNl,
      item.nameDe,
      item.namePl,
      item.nameHu,
      item.newPlant,
      item.noImageAvailable,
      item.primulaType,
      item.plantCode,
      item.potSize,
      item.productTypes,
      item.pottingTemperature,
      item.pottingPeriod,
      item.salesPeriod,
      item.secondPottingPeriod,
      item.secondSalesPeriod,
      item.secondSowingPeriod,
      item.selectedCultivarDescriptionEn,
      item.selectedCultivarDescriptionFr,
      item.selectedCultivarDescriptionNl,
      item.selectedCultivarNameEn,
      item.slug,
      !!item.soils ? item.soils.map(Soil.adapt) : [],
      item.sowingPeriod,
      item.sowingTemperature,
      item.specialPlant,
      item.specialSlug,
      item.specieBotanicName,
      item.specieDescriptionEn,
      item.specieDescriptionFr,
      item.specieDescriptionNl,
      item.specieNameEn,
      item.specieNameFr,
      item.specieNameNl,
      item.spicyness,
      item.stands,
      item.tillHeight,
      item.tillPotSize,
      item.tillPottingPeriod,
      item.tillPottingTemperature,
      item.tillSalesPeriod,
      item.tillSecondPottingPeriod,
      item.tillSecondSalesPeriod,
      item.tillSecondSowingPeriod,
      item.tillSowingPeriod,
      item.tillSowingTemperature,
      item.timing,
      item.trademark,
      item.usages,
      item.image,
      item.selectedCultivarId,
      item.specieId,
      item.familyId,
      item.lastModifiedBy,
      !!item.lastModifiedOn ? DateParser.parseDate(item.lastModifiedOn) : null,
      item.createdBy,
      !!item.createdOn ? DateParser.parseDate(item.createdOn) : null,
      item.raesBreeding,
      item.raesPowerplug,
      item.overrideGenusProperties,
      item.qrCode
    );
  }

  get lifetimeIcon(): string {
    let iconUrl = '';
    if (this.lifetimes != null) {
      iconUrl = 'https://d3coqd91sswgmf.cloudfront.net/icon/' + this.lifetimes[0] + '.png';
    }
    return iconUrl;
  }

  get standIcon(): string {
    let iconUrl = '';
    if (this.stands != null) {
      iconUrl = 'https://d3coqd91sswgmf.cloudfront.net/icon/' + this.stands[0] + '.png';
    }
    return iconUrl;
  }

  get usageIcon(): string {
    let iconUrl = '';
    if (this.usages != null) {
      iconUrl = 'https://d3coqd91sswgmf.cloudfront.net/icon/' + this.usages[0] + '.png';
    }
    return iconUrl;
  }

  get localPottingTemperature(): string {
    if (this.fromPottingTemperature === null && this.tillPottingTemperature === null) {
      return null;
    } else if (this.fromPottingTemperature === null) {
      return `${this.tillPottingTemperature} °C`;
    } else if (this.tillPottingTemperature === null) {
      return `${this.fromPottingTemperature} °C`;
    } else if (this.fromPottingTemperature === this.tillPottingTemperature) {
      return `${this.fromPottingTemperature} °C`;
    } else {
      return `${this.fromPottingTemperature} - ${this.tillPottingTemperature} °C`;
    }
  }

  get localSowingTemperature(): string {
    if (this.fromSowingTemperature === null && this.tillSowingTemperature === null) {
      return '';
    } else if (this.fromSowingTemperature === null) {
      return `${this.tillSowingTemperature} °C`;
    } else if (this.tillSowingTemperature === null) {
      return `${this.fromSowingTemperature} °C`;
    } else if (this.tillSowingTemperature === this.fromSowingTemperature) {
      return `${this.tillSowingTemperature} °C`;
    } else {
      return `${this.fromSowingTemperature} - ${this.tillSowingTemperature} °C`;
    }
  }

  get localPotSize(): string {
    if (this.fromPotSize === null && this.tillPotSize === null) {
      return '';
    } else if (this.fromPotSize === null) {
      return `${this.tillPotSize} cm`;
    } else if (this.tillPotSize === null) {
      return `${this.fromPotSize} cm`;
    } else {
      return `${this.fromPotSize}-${this.tillPotSize} cm`;
    }
  }

  get localHeight(): string {
    if (this.fromHeight === null && this.tillHeight === null) {
      return '';
    } else if (this.fromHeight === null) {
      return `${this.tillHeight} cm`;
    } else if (this.tillHeight === null) {
      return `${this.fromHeight} cm`;
    } else {
      return `${this.fromHeight}-${this.tillHeight} cm`;
    }
  }

  getName(language: string): string {
    switch (language.toLowerCase()) {
      default:
      case 'nl':
        return this.nameNl;
      case 'fr':
        return this.nameFr;
      case 'en':
        return this.nameEn;
    }
  }

  getFullName(language: string): string {
    switch (language.toLowerCase()) {
      default:
      case 'nl':
        return this.fullnameNl;
      case 'fr':
        return this.fullnameFr;
      case 'en':
        return this.fullnameEn;
    }
  }

  getDescription(language: string): string {
    switch (language.toLowerCase()) {
      default:
      case 'nl':
        return this.descriptionNl;
      case 'fr':
        return this.descriptionFr;
      case 'en':
        return this.descriptionEn;
    }
  }

  getPublicImage(): string {
    if (this.noImageAvailable) {
      return `https://d2r5uf6odu3th4.cloudfront.net/seeds/public/noimage.jpg`;
    } else {
      return this.image;
    }
  }

  getGenusName(language: string): string {
    if (language === null || language === undefined) {
      return this.nameNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.genusNameNl;
      case 'fr':
        return this.genusNameFr;
      case 'en':
        return this.genusNameEn;
    }
  }

  getGenusDescription(language: string): string {
    if (language === null || language === undefined) {
      return this.nameNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.genusDescriptionNl;
      case 'fr':
        return this.genusDescriptionFr;
      case 'en':
        return this.genusDescriptionEn;
    }
  }

  getSpecieDescription(language: string): string {
    if (language === null || language === undefined) {
      return this.nameNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.specieDescriptionNl;
      case 'fr':
        return this.specieDescriptionFr;
      case 'en':
        return this.specieDescriptionEn;
    }
  }

  getSelectedCultivarDescription(language: string): string {
    if (language === null || language === undefined) {
      return this.nameNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.selectedCultivarDescriptionNl;
      case 'fr':
        return this.selectedCultivarDescriptionFr;
      case 'en':
        return this.selectedCultivarDescriptionEn;
    }
  }
}
