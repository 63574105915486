import { Injectable } from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {MenuItem} from '@shared/model/layout/menu-item';

@Injectable()
export class SidebarService {
  private menuItemsSource = new ReplaySubject<MenuItem[]>();

    private menuSource = new Subject<string>();
    private resetSource = new Subject();

    menuSource$ = this.menuSource.asObservable();
    resetSource$ = this.resetSource.asObservable();

    getMenuItems(): Observable<MenuItem[]> {
      return this.menuItemsSource.asObservable();
    }

    setMenuItems(menuItems: MenuItem[]) {
      this.menuItemsSource.next(menuItems);
    }

    onMenuStateChange(key: string) {
        this.menuSource.next(key);
    }

    reset() {
        this.resetSource.next(true);
    }
}
