import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {FormGroup, AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Soil} from '@shared/model/soil';
import {Disease} from '@shared/model/disease';
import {Fertilization} from '@shared/model/fertilization';
import {Stand} from '@shared/model/stand';
import {Subscription} from 'rxjs';
import {OptionService} from '@core/services/option.service';
import {PropertiesService} from '@core/http/properties.service';
import {FlowerMonth} from '@shared/model/flowerMonth';
import {Specie} from '@shared/model/specie';

/**
 * Custom validator for range fields that validates:
 * 1. If 'to' value is provided, 'from' value must also be provided
 * 2. If both values are provided, 'from' must be less than 'to'
 *
 * @param fromControlName The name of the 'from' form control
 * @param toControlName The name of the 'to' form control
 * @returns A validator function for the form group
 */
export function rangeValidator(fromControlName: string, toControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const fromControl = formGroup.get(fromControlName);
    const toControl = formGroup.get(toControlName);

    // Skip validation if controls don't exist
    if (!fromControl || !toControl) {
      return null;
    }

    // Get the values, converting empty strings to null for easier comparison
    const fromValue = fromControl.value === '' ? null : fromControl.value;
    const toValue = toControl.value === '' ? null : toControl.value;

    // Reset previous errors to avoid validation conflicts
    const fromErrors = fromControl.errors ? { ...fromControl.errors } : null;
    const toErrors = toControl.errors ? { ...toControl.errors } : null;

    if (fromErrors) {
      delete fromErrors.fromGreaterThanOrEqualTo;
      if (Object.keys(fromErrors).length === 0) {
        fromControl.setErrors(null);
      } else {
        fromControl.setErrors(fromErrors);
      }
    }

    if (toErrors) {
      delete toErrors.toWithoutFrom;
      delete toErrors.toSmallerThanOrEqualTo;
      if (Object.keys(toErrors).length === 0) {
        toControl.setErrors(null);
      } else {
        toControl.setErrors(toErrors);
      }
    }

    // Error cases
    if (toValue !== null && fromValue === null) {
      // Case 1: 'to' value is provided but 'from' value is missing
      toControl.setErrors({ ...(toControl.errors || {}), toWithoutFrom: true });
      return { toWithoutFrom: true };
    } else if (fromValue !== null && toValue !== null && Number(fromValue) >= Number(toValue)) {
      // Case 2: Both values provided but 'from' is not less than 'to'
      fromControl.setErrors({ ...(fromControl.errors || {}), fromGreaterThanOrEqualTo: true });
      toControl.setErrors({ ...(toControl.errors || {}), toSmallerThanOrEqualTo: true });
      return { fromGreaterThanOrEqualTo: true };
    }

    return null;
  };
}

/**
 * Get the numeric order of a month from the FlowerMonth enum
 * @param month The FlowerMonth enum value
 * @returns A number representing the month's order (1-12), or 0 for NONE
 */
function getMonthOrder(month: FlowerMonth | null): number {
  if (!month || month === FlowerMonth.NONE) { return 0; }

  const monthOrders = {
    [FlowerMonth.JANUARY]: 1,
    [FlowerMonth.FEBRUARY]: 2,
    [FlowerMonth.MARCH]: 3,
    [FlowerMonth.APRIL]: 4,
    [FlowerMonth.MAY]: 5,
    [FlowerMonth.JUNE]: 6,
    [FlowerMonth.JULY]: 7,
    [FlowerMonth.AUGUST]: 8,
    [FlowerMonth.SEPTEMBER]: 9,
    [FlowerMonth.OCTOBER]: 10,
    [FlowerMonth.NOVEMBER]: 11,
    [FlowerMonth.DECEMBER]: 12
  };

  return monthOrders[month] || 0;
}

/**
 * Custom validator for month range fields that validates:
 * 1. Either both month fields must be filled in or both must be empty
 * 2. If both are filled in, 'from' must not be later than 'till'
 *
 * @param fromMonthControlName The name of the 'from' month form control
 * @param tillMonthControlName The name of the 'till' month form control
 * @returns A validator function for the form group
 */
export function monthRangeValidator(fromMonthControlName: string, tillMonthControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const fromMonthControl = formGroup.get(fromMonthControlName);
    const tillMonthControl = formGroup.get(tillMonthControlName);

    // Skip validation if controls don't exist
    if (!fromMonthControl || !tillMonthControl) {
      return null;
    }

    // Get the month values
    const fromMonth = fromMonthControl.value;
    const tillMonth = tillMonthControl.value;

    // Reset previous errors to avoid validation conflicts
    const fromErrors = fromMonthControl.errors ? { ...fromMonthControl.errors } : null;
    const toErrors = tillMonthControl.errors ? { ...tillMonthControl.errors } : null;

    if (fromErrors) {
      delete fromErrors.fromMonthLaterThan;
      delete fromErrors.monthRequiresBoth;
      if (Object.keys(fromErrors).length === 0) {
        fromMonthControl.setErrors(null);
      } else {
        fromMonthControl.setErrors(fromErrors);
      }
    }

    if (toErrors) {
      delete toErrors.tillMonthEarlierThan;
      delete toErrors.monthRequiresBoth;
      if (Object.keys(toErrors).length === 0) {
        tillMonthControl.setErrors(null);
      } else {
        tillMonthControl.setErrors(toErrors);
      }
    }

    // Case 1: One field filled but not the other - both must be filled or both empty
    if ((fromMonth && !tillMonth) || (!fromMonth && tillMonth)) {
      fromMonthControl.setErrors({ ...(fromMonthControl.errors || {}), monthRequiresBoth: true });
      tillMonthControl.setErrors({ ...(tillMonthControl.errors || {}), monthRequiresBoth: true });
      return { monthRequiresBoth: true };
    }

    // Both are either filled or empty at this point
    // If both are empty, validation passes
    if (!fromMonth && !tillMonth) {
      return null;
    }

    // Case 2: Both are filled, check if 'from' is later than 'till'
    const fromMonthOrder = getMonthOrder(fromMonth);
    const tillMonthOrder = getMonthOrder(tillMonth);

    if (fromMonthOrder > tillMonthOrder) {
      fromMonthControl.setErrors({ ...(fromMonthControl.errors || {}), fromMonthLaterThan: true });
      tillMonthControl.setErrors({ ...(tillMonthControl.errors || {}), tillMonthEarlierThan: true });
      return { fromMonthLaterThan: true };
    }

    return null;
  };
}

@Component({
  selector: 'app-genus-specie-properties-tab',
  templateUrl: './genus-specie-properties-tab.component.html'
})
export class GenusSpeciePropertiesTabComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;

  public fertilizations: Fertilization[] = [];
  public diseases: Disease[] = [];
  public soils: Soil[] = [];

  public stands: Stand[] = [];
  public months: FlowerMonth[] = [];
  diseaseLabel2: string | ((option: Disease) => string) = this.diseaseLabel;
  soilLabel2: string | ((option: Soil) => string) = this.soilLabel;

  subscriptions: Subscription[] = [];

  constructor(private optionService: OptionService,
              private propertiesService: PropertiesService) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.optionService.getStands().subscribe(stands => this.stands = stands),
      this.optionService.getFlowerMonths().subscribe(months => this.months = months),
      this.optionService.getFertilizations().subscribe(fertilizations => this.fertilizations = fertilizations),
      this.propertiesService.retrieveDiseases().subscribe(diseases => this.diseases = diseases),
      this.propertiesService.retrieveSoils().subscribe(soils => this.soils = soils),
    );

    // Apply validators after the form is available
    this.applyRangeValidators();
    this.applyMonthRangeValidators();
  }

  /**
   * Apply the custom range validators to temperature ranges
   */
  private applyRangeValidators(): void {
    if (this.form) {
      // Add validators to the form group
      this.form.addValidators([
        // Sowing temperature range validation
        rangeValidator('fromSowingTemperature', 'tillSowingTemperature'),
        // Potting temperature range validation
        rangeValidator('fromPottingTemperature', 'tillPottingTemperature')
      ]);

      // Update the form validation status
      this.form.updateValueAndValidity();

      // Listen for changes in the range fields to re-validate
      this.subscriptions.push(
        this.form.get('fromSowingTemperature')?.valueChanges.subscribe(() =>
          this.form.updateValueAndValidity()
        ),
        this.form.get('tillSowingTemperature')?.valueChanges.subscribe(() =>
          this.form.updateValueAndValidity()
        ),
        this.form.get('fromPottingTemperature')?.valueChanges.subscribe(() =>
          this.form.updateValueAndValidity()
        ),
        this.form.get('tillPottingTemperature')?.valueChanges.subscribe(() =>
          this.form.updateValueAndValidity()
        )
      );
    }
  }

  /**
   * Apply the custom month range validators to month fields
   */
  private applyMonthRangeValidators(): void {
    if (this.form) {
      // Add validators to the form group
      this.form.addValidators([
        // Month range validations
        monthRangeValidator('fromSowingMonth', 'tillSowingMonth'),
        monthRangeValidator('fromSowingSecondMonth', 'tillSowingSecondMonth'),
        monthRangeValidator('fromPottingMonth', 'tillPottingMonth'),
        monthRangeValidator('fromPottingSecondMonth', 'tillPottingSecondMonth'),
        monthRangeValidator('fromSalesMonth', 'tillSalesMonth'),
        monthRangeValidator('fromSalesSecondMonth', 'tillSalesSecondMonth')
      ]);

      // Update the form validation status
      this.form.updateValueAndValidity();

      // Set up subscriptions for month fields to re-validate on change
      const monthFields = [
        'fromSowingMonth', 'tillSowingMonth',
        'fromSowingSecondMonth', 'tillSowingSecondMonth',
        'fromPottingMonth', 'tillPottingMonth',
        'fromPottingSecondMonth', 'tillPottingSecondMonth',
        'fromSalesMonth', 'tillSalesMonth',
        'fromSalesSecondMonth', 'tillSalesSecondMonth'
      ];

      // Create valueChanges subscriptions for all month fields
      monthFields.forEach(fieldName => {
        const control = this.form.get(fieldName);
        if (control) {
          this.subscriptions.push(
            control.valueChanges.subscribe(() => this.form.updateValueAndValidity())
          );
        }
      });
    }
  }

  public soilLabel(option: Soil): string {
    return option.nameNl;
  }

  public soilValue(option: Soil): string {
    return option.id;
  }

  public diseaseLabel(option: Disease): string {
    return option.nameNl;
  }

  public diseaseValue(option: Disease): string {
    return option.id;
  }

  /**
   * Clean up subscriptions when component is destroyed
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
