<div class="border-1 surface-border border-round">
  <span class="text-900 font-bold block border-bottom-1 surface-border p-3">{{'taxonomy.audit.title' | translate}}</span>
  <div class="p-3">
    <div class="border-1 surface-border mb-2 flex justify-content-between align-items-center py-2 px-3 border-round">
      <span class="text-900 font-bold p-3">{{'taxonomy.audit.createdBy' | translate}}</span>
        {{auditable.createdBy}}
    </div>
    <div class="border-1 surface-border mb-2 flex justify-content-between align-items-center py-2 px-3 border-round">
      <span class="text-900 font-bold p-3">{{'taxonomy.audit.createdOn' | translate}}</span>
      {{ auditable?.createdOn | date:'MM/dd/yyyy, HH:mm' }}
    </div>
    <div class="border-1 surface-border mb-2 flex justify-content-between align-items-center py-2 px-3 border-round">
      <span class="text-900 font-bold p-3">{{'taxonomy.audit.changedBy' | translate}}</span>
      {{auditable.lastModifiedBy}}
    </div>
    <div class="border-1 surface-border mb-2 flex justify-content-between align-items-center py-2 px-3 border-round">
      <span class="text-900 font-bold p-3">{{'taxonomy.audit.changedOn' | translate}}</span>
      {{ auditable?.lastModifiedOn | date:'MM/dd/yyyy, HH:mm' }}     </div>
  </div>
</div>
