import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {ValidationUtil} from '@shared/validation/validation-util.service';
import {InputType} from '@shared/components/input/input-type.enum';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() label: string;
  @Input() orientation: InputType = InputType.VERTICAL;
  @Input() control: FormControl;

  constructor(private validationUtil: ValidationUtil) {
  }

  ngOnInit(): void {
  }

  get required(): boolean {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);

      return validator && validator.required;
    }

    return false;
  }

  isVertical(): boolean {
    return this.orientation === InputType.VERTICAL;
  }

  get hasErrors(): boolean {
    return this.control.dirty && !this.control.valid;
  }

  get error(): string {
    if (this.hasErrors && this.control?.errors !== null && Object.keys(this.control.errors).length !== 0) {
      const firstError = Object.keys(this.control.errors)[0];
      return this.validationUtil.handleError(firstError, this.control.errors[firstError]);
    }
  }

}
