export const taxonomyRoutesNames = {
  DASHBOARD: '',
  FAMILY_OVERVIEW: 'family',
  FAMILY_CREATE: 'family/create',
  FAMILY_EDIT: 'family/:id/edit',
  GENUS_OVERVIEW: 'genus',
  GENUS_CREATE: 'genus/create',
  GENUS_EDIT: 'genus/:id/edit',
  SPECIE_OVERVIEW: 'specie',
  SPECIE_CREATE: 'specie/create',
  SPECIE_EDIT: 'specie/:id/edit',
  SELECTEDCULTIVAR_OVERVIEW: 'selectedcultivar',
  SELECTEDCULTIVAR_CREATE: 'selectedcultivar/create',
  SELECTEDCULTIVAR_EDIT: 'selectedcultivar/:id/edit',
  PRODUCT_OVERVIEW: 'product',
  FLOWERPLANPRODUCT_OVERVIEW: 'flowerplanproduct',
  FLOWERPLANPRODUCT_EDIT: 'flowerplanproduct/:id/edit',
  PRODUCT_CREATE: 'product/create',
  PRODUCT_EDIT: 'product/:id/edit',
  PLANT_OVERVIEW: 'plant',
  BRIDGE_PRODUCT_OVERVIEW: 'product',
  BRIDGE_PRODUCT_EDIT: 'product/:id/edit',
  PLANT_CREATE: 'plant/create',
  PLANT_EDIT: 'plant/:id/edit',
  PLANT_CATALOG: 'plant/:id/catalog',
  PRODUCT_IMPORT: 'import/product',
  PLANT_IMPORT: 'import/plant',
  SELECTEDCULTIVAR_IMPORT: 'import/selectedcultivar',
  PRODUCT_EXPORT: 'export/product'
};
