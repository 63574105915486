import {Component, OnInit} from '@angular/core';
import {LayoutService} from './service/app.layout.service';
import {CurrentUserService} from '@core/services/current-user.service';
import {appRoutesLinks} from '@app/app.routes.links';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  model: any[] = [];

  constructor(public layoutService: LayoutService, private currentUserService: CurrentUserService
  ) {
  }

  ngOnInit() {
    this.model = [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        visible: this.currentUserService.hasAnyPermission(['access:admin-panel']),
        items: [
          {
            label: 'Overzicht',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['access:admin-panel']),
            routerLink: appRoutesLinks.HOME
          }
        ]
      },
      /*{
        label: 'Image DB',
        icon: 'pi pi-fw pi-image',
        visible: this.currentUserService.hasAnyPermission(['read:images']),
        items: [
          {
            label: 'Overzicht',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:images']),
            routerLink: appRoutesLinks.IMAGEDB_OVERVIEW
          }
        ]
      },
      */
      {
        label: 'Stock',
        icon: 'pi pi-fw pi-check-square',
        visible: this.currentUserService.hasAnyPermission(['create:availability', 'read:availability',
          'update:availability', 'delete:availability']),
        items: [
          {
            label: 'Overzicht',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:availability',
              'update:availability', 'delete:availability']),
            routerLink: appRoutesLinks.AVAILABILITY_OVERVIEW
          }
        ]
      },
      {
        label: 'Digitale Catalogi',
        icon: 'pi pi-fw pi-book',
        visible: this.currentUserService.hasAnyPermission(['create:catalog', 'read:catalog',
          'update:catalog', 'delete:catalog']),
        items: [
          {
            label: 'Overzicht',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:catalog',
              'update:catalog', 'delete:catalog']),
            routerLink: appRoutesLinks.DIGITALCATALOG_OVERVIEW
          },
          {
            label: 'Aanmaken',
            icon: 'pi pi-fw pi-plus',
            visible: this.currentUserService.hasPermission('create:catalog'),
            routerLink: appRoutesLinks.DIGITALCATALOG_CREATE
          }
        ]
      },
      /*{
        label: 'XML Catalogi',
        icon: 'pi pi-fw pi-book',
        visible: this.currentUserService.hasAnyPermission(['create:catalog', 'read:catalog',
          'update:catalog', 'delete:catalog']),
        items: [
          {
            label: 'Aanmaken',
            icon: 'pi pi-fw pi-plus',
            visible: this.currentUserService.hasPermission('create:catalog'),
            routerLink: appRoutesLinks.XMLCATALOG_GENERATE
          }
        ]
      },*/
      {
        label: 'Bestelformulieren',
        icon: 'pi pi-fw pi-inbox',
        visible: this.currentUserService.hasAnyPermission(['create:orderform', 'read:orderform',
          'update:orderform', 'delete:orderform']),
        items: [
          {
            label: 'Overzicht',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:orderform',
              'update:orderform', 'delete:orderform']),
            routerLink: appRoutesLinks.ORDERFORM_OVERVIEW
          },
          {
            label: 'Aanmaken',
            icon: 'pi pi-fw pi-plus',
            visible: this.currentUserService.hasPermission('create:orderform'),
            routerLink: appRoutesLinks.ORDERFORM_CREATE
          }
        ]
      },
      {
        label: 'Jobs',
        icon: 'pi pi-fw pi-briefcase',
        visible: this.currentUserService.hasAnyPermission(['create:job', 'read:job',
          'update:job', 'delete:job']),
        items: [
          {
            label: 'Vacatures',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:job',
              'update:job', 'delete:job']),
            routerLink: appRoutesLinks.JOB_OVERVIEW
          },
          {
            label: 'Aanmaken',
            icon: 'pi pi-fw pi-plus',
            visible: this.currentUserService.hasPermission('create:job'),
            routerLink: appRoutesLinks.JOB_CREATE
          },
          {
            label: 'Sollicitaties',
            icon: 'pi pi-fw pi-comments',
            routerLink: appRoutesLinks.JOBAPPLY_OVERVIEW
          },
        ]
      },
      {
        label: 'Nieuwsberichten',
        icon: 'pi pi-fw pi-volume-up',
        visible: this.currentUserService.hasAnyPermission(['create:news', 'read:news',
          'update:news', 'delete:news']),
        items: [
          {
            label: 'Overzicht',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:news',
              'update:news', 'delete:news']),
            routerLink: appRoutesLinks.NEWSITEM_OVERVIEW
          },
          {
            label: 'Aanmaken',
            icon: 'pi pi-fw pi-plus',
            visible: this.currentUserService.hasPermission('create:news'),
            routerLink: appRoutesLinks.NEWSITEM_CREATE
          }
        ]
      },
      {
        label: 'Vertegenwoordigers',
        icon: 'pi pi-fw pi-volume-up',
        visible: this.currentUserService.hasAnyPermission(['create:news', 'read:news',
          'update:news', 'delete:news']),
        items: [
          {
            label: 'Overzicht',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:news',
              'update:news', 'delete:news']),
            routerLink: appRoutesLinks.REPRESENTATIVE_OVERVIEW
          },
          {
            label: 'Aanmaken',
            icon: 'pi pi-fw pi-plus',
            visible: this.currentUserService.hasPermission('create:news'),
            routerLink: appRoutesLinks.REPRESENTATIVE_CREATE
          }
        ]
      },
      {
        label: 'Slideshow',
        icon: 'pi pi-fw pi-volume-up',
        visible: this.currentUserService.hasAnyPermission(['create:news', 'read:news',
          'update:news', 'delete:news']),
        items: [
          {
            label: 'Overzicht',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:news',
              'update:news', 'delete:news']),
            routerLink: appRoutesLinks.SLIDESHOW_OVERVIEW
          },
          {
            label: 'Aanmaken',
            icon: 'pi pi-fw pi-plus',
            visible: this.currentUserService.hasPermission('read:news'),
            routerLink: appRoutesLinks.SLIDESHOW_CREATE
          }
        ]
      },
      {
        label: 'Contact Info',
        icon: 'pi pi-fw pi-envelope',
        visible: this.currentUserService.hasAnyPermission(['create:contact', 'read:contact',
          'update:contact', 'delete:contact']),
        items: [
          {
            label: 'Overzicht',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:contact',
              'update:contact', 'delete:contact']),
            routerLink: appRoutesLinks.CONTACTINFO_OVERVIEW
          }
        ]
      },
      {
        label: 'Taxonomie', icon: 'pi pi-fw pi-sitemap',
        visible: this.currentUserService.hasAnyPermission(['create:taxonomy', 'read:taxonomy',
          'update:taxonomy', 'delete:taxonomy']),
        items: [
          /* {
             label: 'Dashboard',
             icon: 'pi pi-fw pi-home',
             visible: this.currentUserService.hasAnyPermission(['read:taxonomy']),
             routerLink: appRoutesLinks.TAXONOMY_DASHBOARD
           },*/
          {
            label: 'Familie', icon: 'pi pi-fw pi-globe',
            items: [
              {
                label: 'Overzicht', icon: 'pi pi-fw pi-list',
                visible: this.currentUserService.hasAnyPermission(['read:taxonomy',
                  'update:taxonomy', 'delete:taxonomy']),
                routerLink: appRoutesLinks.FAMILY_OVERVIEW
              },
              {
                label: 'Aanmaken', icon: 'pi pi-fw pi-plus',
                visible: this.currentUserService.hasPermission('create:taxonomy'),
                routerLink: appRoutesLinks.FAMILY_CREATE
              },
            ]
          },
          {
            label: 'Genus', icon: 'pi pi-fw pi-palette',
            items: [
              {
                label: 'Overzicht', icon: 'pi pi-fw pi-list',
                visible: this.currentUserService.hasAnyPermission(['read:taxonomy',
                  'update:taxonomy', 'delete:taxonomy']),
                routerLink: appRoutesLinks.GENUS_OVERVIEW
              },
              {
                label: 'Aanmaken', icon: 'pi pi-fw pi-plus',
                visible: this.currentUserService.hasPermission('create:taxonomy'),
                routerLink: appRoutesLinks.GENUS_CREATE
              },
            ]
          },
          {
            label: 'Specie', icon: 'pi pi-fw pi-compass',
            items: [
              {
                label: 'Overzicht', icon: 'pi pi-fw pi-list',
                visible: this.currentUserService.hasAnyPermission(['read:taxonomy',
                  'update:taxonomy', 'delete:taxonomy']),
                routerLink: appRoutesLinks.SPECIE_OVERVIEW
              },
              {
                label: 'Aanmaken', icon: 'pi pi-fw pi-plus',
                visible: this.currentUserService.hasPermission('create:taxonomy'),
                routerLink: appRoutesLinks.SPECIE_CREATE
              },
            ]
          },
          {
            label: 'Selected Cultivar', icon: 'pi pi-fw pi-eye',
            items: [
              {
                label: 'Overzicht', icon: 'pi pi-fw pi-list',
                visible: this.currentUserService.hasAnyPermission(['read:taxonomy',
                  'update:taxonomy', 'delete:taxonomy']),
                routerLink: appRoutesLinks.SELECTEDCULTIVAR_OVERVIEW
              },
              {
                label: 'Aanmaken', icon: 'pi pi-fw pi-plus',
                visible: this.currentUserService.hasPermission('create:taxonomy'),
                routerLink: appRoutesLinks.SELECTEDCULTIVAR_CREATE
              },
            ]
          },
          {
            label: 'Plant', icon: 'pi pi-fw pi-key',
            items: [
              {
                label: 'Overzicht', icon: 'pi pi-fw pi-list',
                visible: this.currentUserService.hasAnyPermission(['read:taxonomy',
                  'update:taxonomy', 'delete:taxonomy']),
                routerLink: appRoutesLinks.PLANT_OVERVIEW
              },
              {
                label: 'Aanmaken', icon: 'pi pi-fw pi-plus',
                visible: this.currentUserService.hasPermission('create:taxonomy'),
                routerLink: appRoutesLinks.PLANT_CREATE
              }
            ]
          },
          {
            label: 'Flowerplan Product', icon: 'pi pi-fw pi-android',
            items: [
              {
                label: 'Overzicht', icon: 'pi pi-fw pi-list',
                visible: this.currentUserService.hasAnyPermission(['read:taxonomy',
                  'update:taxonomy', 'delete:taxonomy']),
                routerLink: appRoutesLinks.FLOWERPLANPRODUCT_OVERVIEW
              }
            ]
          },
          {
            label: 'Imports', icon: 'pi pi-fw pi-sign-in',
            items: [
              {
                label: 'Plants', icon: 'pi pi-fw pi-android',
                visible: this.currentUserService.hasPermission('create:taxonomy'),
                routerLink: appRoutesLinks.PLANT_IMPORT
              },
              {
                label: 'Products', icon: 'pi pi-fw pi-android',
                visible: this.currentUserService.hasPermission('create:taxonomy'),
                routerLink: appRoutesLinks.PRODUCT_IMPORT
              },
              {
                label: 'Selected Cultivars', icon: 'pi pi-fw pi-android',
                visible: this.currentUserService.hasPermission('create:taxonomy'),
                routerLink: appRoutesLinks.SELECTEDCULTIVAR_IMPORT
              }
            ]
          },
          {
            label: 'Exports', icon: 'pi pi-fw pi-sign-out',
            items: [
              {
                label: 'products', icon: 'pi pi-fw pi-android',
                routerLink: appRoutesLinks.PRODUCT_EXPORT
              }
            ]
          }
        ]
      },
      {
        label: 'Gebruikers',
        icon: 'pi pi-fw pi-user',
        visible: this.currentUserService.hasAnyPermission(['create:user', 'read:users',
          'edit:user', 'delete:user']),
        items: [
          {
            label: 'Overzicht',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:users',
              'edit:user', 'delete:user']),
            routerLink: appRoutesLinks.USER_OVERVIEW
          },
          {
            label: 'Aanmaken', icon: 'pi pi-fw pi-plus',
            visible: this.currentUserService.hasPermission('create:user'),
            routerLink: appRoutesLinks.USER_CREATE
          }
        ]
      },
      {
        label: 'Klanten',
        icon: 'pi pi-fw pi-apple',
        visible: this.currentUserService.hasAnyPermission(['read:customers',
          'edit:customer']),
        items: [
          {
            label: 'Overzicht',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:customers',
              'edit:customer']),
            routerLink: appRoutesLinks.CUSTOMER_OVERVIEW
          }
        ]
      },
      {
       label: 'Flowerplan',
       icon: 'pi pi-fw pi-prime',
       visible: this.currentUserService.hasAnyPermission(['read:flowerplan']),
       items: [
         {
           label: 'Overzicht',
           icon: 'pi pi-fw  pi-list',
           visible: this.currentUserService.hasAnyPermission(['read:flowerplan']),
           routerLink: appRoutesLinks.BRIDGE_OVERVIEW
         },
         {
           label: 'Producten synchronisatie',
           icon: 'pi pi-fw  pi-list',
           visible: this.currentUserService.hasAnyPermission(['read:flowerplan']),
           routerLink: appRoutesLinks.BRIDGE_PRODUCT_OVERVIEW
         },
         {
           label: 'klanten synchronisatie',
           icon: 'pi pi-fw pi-list',
           visible: this.currentUserService.hasAnyPermission(['read:flowerplan']),
           routerLink: appRoutesLinks.BRIDGE_CUSTOMER_OVERVIEW
         },
         {
           label: 'Beschikbaarheden synchronisatie',
           icon: 'pi pi-prime pi-list',
           visible: this.currentUserService.hasAnyPermission(['read:flowerplan']),
           routerLink: appRoutesLinks.BRIDGE_STOCK_OVERVIEW
         }
       ]
     }
      /*{
        label: 'Bestellingen',
        icon: 'pi pi-fw pi-id-card',
        visible: this.currentUserService.hasAnyPermission(['create:order', 'read:orders']),
        items: [
          {
            label: 'Overzicht',
            icon: 'pi pi-fw pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:orders']),
            routerLink: appRoutesLinks.ORDER_OVERVIEW
          }
        ]
      },*/
      /*{
        label: 'Vertalingen',
        icon: 'pi pi-eye pi-id-card',
        visible: this.currentUserService.hasAnyPermission(['update:translations', 'read:translations']),
        items: [
          {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-home',
            visible: this.currentUserService.hasAnyPermission(['read:translations']),
            routerLink: appRoutesLinks.TRANSLATION_DASHBOARD
          },
          {
            label: 'Plant Overzicht',
            icon: 'pi pi-eye pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:translations']),
            routerLink: appRoutesLinks.TRANSLATION_PLANT_OVERVIEW
          },
          // {
          //   label: 'Product Overzicht',
          //   icon: 'pi pi-eye pi-list',
          //   visible: this.currentUserService.hasAnyPermission(['read:translations']),
          //   routerLink: appRoutesLinks.TRANSLATION_PRODUCT_OVERVIEW
          // },
          {
            label: 'SC Overzicht',
            icon: 'pi pi-eye pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:translations']),
            routerLink: appRoutesLinks.TRANSLATION_SELECTEDCULTIVAR_OVERVIEW
          },
          {
            label: 'Specie Overzicht',
            icon: 'pi pi-eye pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:translations']),
            routerLink: appRoutesLinks.TRANSLATION_SPECIE_OVERVIEW
          },
          {
            label: 'Genus Overzicht',
            icon: 'pi pi-eye pi-list',
            visible: this.currentUserService.hasAnyPermission(['read:translations']),
            routerLink: appRoutesLinks.TRANSLATION_GENUS_OVERVIEW
          }
        ]
      }*/
    ];
  }
}
