import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControlDirective} from '@shared/directives/abstract-control.directive';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent extends AbstractControlDirective{
  @Input('options') _options: any[];
  @Input() optionLabel: (option: any) => string;
  @Input() optionValue: (option: any) => any = this.defaultValue;
  @Input() showHeader = true;
  @Input() filter = true;
  @Input() showClear = false;
  @Input() virtualScroll: boolean;
  @Input() placeholder = 'Choose';
  @Input() selectedItemsLabel = '{0} items selected';
  @Input() emptyFilterMessage = 'No results found';

  @Output() onDropdownValueChanged: EventEmitter<number> = new EventEmitter<number>();

  get options() {
    if (this.optionLabel) {
      this._options.map(option => {
        option.label = this.optionLabel(option);
        option.value = this.optionValue(option);
        return option;
      });
    }

    return this._options;
  }

  defaultValue(option: any): any {
    return option?.id;
  }

  onDropdownChange($event: any) {
    this.onDropdownValueChanged.emit($event.value);
  }
}
