<div class="card">
  <div class="tw-card-align-title-and-actions tw-p-mb-4">
    <div class="tw-border-b tw-border-gray-200 tw-bg-white tw-px-4 tw-py-5 tw-sm:px-6 tw-mb-5">
      <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-green-800">{{ title }}</h3>
    </div>
    <ng-content select="[actions]"></ng-content>
  </div>

  <ng-content></ng-content>
</div>
