<div class="grid">
  <div class="col-12">
    <div class="card px-6 py-6">
      <h5>Overzicht van de vertegenwoordigers</h5>
      <p-toast></p-toast>

      <p-table #dt
               [value]="representatives"
               [columns]="cols"
               [lazy]="true"
               responsiveLayout="scroll"
               [rows]="20"
               [totalRecords]="page?.totalElements"
               [paginator]="true"
               [first]="first"
               (onLazyLoad)="loadRepresentatives($event)"
               [loading]="loading"
               [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,20,30,50]"
               currentPageReportTemplate="Toon {first} tot {last} van {totalRecords} items"
               [(selection)]="selectedRepresentative"
               selectionMode="single"
               [sortMode]="'single'"
               [rowHover]="true"
               [defaultSortOrder]="1"
               dataKey="id">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 4rem">#</th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngSwitch]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns let-rowIndex="rowIndex">
          <tr>
            <td>{{calculateRowNumber(rowIndex)}}</td>
            <td>
              <span class="p-column-title">{{columns[0]}}</span>
              {{rowData.firstName}}
            </td>
            <td>
              <span class="p-column-title">{{columns[1]}}</span>
              {{rowData.lastName}}
            </td>
            <td>
              <span class="p-column-title">{{columns[2]}}</span>
              {{countryLabel[currentLanguage][rowData.country]}}
            </td>
            <td>
              <span class="p-column-title">{{columns[3]}}</span>
              {{rowData.email}}
            </td>
            <td>
              <span class="p-column-title">{{columns[4]}}</span>
              {{rowData.company}}
            </td>
            <td>
              <div class="flex">
                <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="edit(rowData.id)" pTooltip="Vertegenwoordiger aanpassen"></button>
                <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="delete(rowData.id, rowData.lastName)" pTooltip="Vertegenwoordiger verwijderen"></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
  </div>
</div>
