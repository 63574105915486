import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OAuthResourceServerErrorHandler, OAuthStorage} from 'angular-oauth2-oidc';
import {Observable} from 'rxjs';
import {AppConfigService} from '@core/providers/app-config.service';

@Injectable()
export class OauthInterceptor implements HttpInterceptor {
  constructor(
    private authStorage: OAuthStorage,
    private errorHandler: OAuthResourceServerErrorHandler,
    private configProvider: AppConfigService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url.toLowerCase();

    if (!this.configProvider.config()
      || !this.configProvider.config().auth
      || !this.configProvider.config().auth.allowedUrls
      || !this.checkUrl(url)) {
      return next.handle(request);
    }

    const token = this.authStorage.getItem('access_token');
    const header = `Bearer ${token}`;

    const headers = request.headers.set('Authorization', header);

    request = request.clone({headers});

    try {
      return next.handle(request);
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  private checkUrl(url: string): boolean {
    let found = false;

    for (const api of this.configProvider.config().auth.allowedUrls) {
      if (url.startsWith(api)) {
        found = true;

        break;
      }
    }

    return !!found;
  }
}
