export class ImportResult {
  constructor(public familiesCreated: number = 0,
              public geniCreated: number = 0,
              public speciesCreated: number = 0,
              public selectedCultivarCreated: number = 0,
              public productsProcessed: number = 0,
              public productsFailed: number = 0,
              public productsCreated: number = 0,
              public productsUpdated: number = 0,
              public productsArchived: number = 0,
              public productsDeleted: number = 0,
              public productsReactivated: number = 0,
              public errors: ImportException[] = []) {
  }

  static adapt(item: any): ImportResult {
    return new ImportResult(
      item.familiesCreated,
      item.geniCreated,
      item.speciesCreated,
      item.selectedCultivarCreated,
      item.productsProcessed,
      item.productsFailed,
      item.productsCreated,
      item.productsUpdated,
      item.productsArchived,
      item.productsDeleted,
      item.productsReactivated,
      !!item.errors ? item.errors.map(ImportException.adapt) : []
    );
  }
}

class ImportException {
  constructor(public row: number = -1,
              public message: string = '') {
  }

  static adapt(item: any): ImportException {
    return new ImportException(
      item.row,
      item.error
    );
  }
}
