import {Injectable, Input, signal} from '@angular/core';
import {Subject} from 'rxjs';
import {BreadcrumbItem} from '@shared/model/layout/breadcrumb-item';
import {MenuItem} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private itemSource = new Subject<BreadcrumbItem[]>();
  private titleSource = new Subject<string>();
  private _items = signal<MenuItem[]>([]);

  itemsHandler = this.itemSource.asObservable();

  setItems(items: BreadcrumbItem[]) {
    this.itemSource.next(items);
  }

  get items() {
    return this._items.asReadonly();
  }

  setItemss(items: MenuItem[]) {
    this._items.set(items);
  }

  addItem(item: MenuItem) {
    this._items.update(items => [...items, item]);
  }

  updateItem(index: number, item: MenuItem) {
    this._items.update(items => {
      const newItems = [...items];
      newItems[index] = item;
      return newItems;
    });
  }

  removeItem(index: number) {
    this._items.update(items => items.filter((_, i) => i !== index));
  }

  clearItems() {
    this._items.set([]);
  }

}
