import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-taxonomy-translations',
  templateUrl: './taxonomy-translations.component.html'
})
export class TaxonomyTranslationsComponent implements OnInit {
  get langs(): ['NL', 'FR', 'EN', 'DE'] {
    return this._langs;
  }
  @Input() form: FormGroup;
  @Input() editMode: false;

  translateCriteria = new FormGroup({
    translatableDescription: new FormControl(),
    sourceLanguage: new FormControl('EN')
  });
  reloadSuggestions = new EventEmitter<string>();
  private _langs: ['NL', 'FR', 'EN', 'DE'];
  radioValue1: any;

  constructor() { }

  ngOnInit(): void {
    this.translateCriteria.controls.translatableDescription.valueChanges.subscribe(text => {
      this.reloadSuggestions.next(text);
    });

    this.translateCriteria.controls.sourceLanguage.valueChanges.subscribe(language => {
      this.reloadSuggestions.next(this.translateCriteria.controls.translatableDescription.value);
    });
  }

  /**
   * Copies the translation suggestion to the generic description field
   * @param lang Language suffix (e.g., 'Nl', 'En', 'Fr')
   * @param text The translation text to copy
   */
  onCopyToGeneric(lang: string, text: string): void {
    if (text && this.form) {
      const controlName = `genericDescription${lang}`;
      if (this.form.get(controlName)) {
        this.form.get(controlName).setValue(text);
      }
    }
  }

  /**
   * Copies the translation suggestion to the seeds description field
   * @param lang Language suffix (e.g., 'Nl', 'En', 'Fr')
   * @param text The translation text to copy
   */
  onCopyToSeeds(lang: string, text: string): void {
    if (text && this.form) {
      const controlName = `seedsDescription${lang}`;
      if (this.form.get(controlName)) {
        this.form.get(controlName).setValue(text);
      }
    }
  }

  /**
   * Copies the translation suggestion to the cuttings description field
   * @param lang Language suffix (e.g., 'Nl', 'En', 'Fr')
   * @param text The translation text to copy
   */
  onCopyToCuttings(lang: string, text: string): void {
    if (text && this.form) {
      const controlName = `cuttingsDescription${lang}`;
      if (this.form.get(controlName)) {
        this.form.get(controlName).setValue(text);
      }
    }
  }
}
