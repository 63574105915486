export enum Flowerpattern {
  SPOTTED = 'SPOTTED' as any,
  NONE = 'NONE' as any,
  WITH_EYE = 'WITH_EYE' as any,
  STRIPED = 'STRIPED' as any,
  VEINED = 'VEINED' as any,
  PICOTEE = 'PICOTEE' as any,
  FLAMED = 'FLAMED' as any,
  MONOCHROMATIC = 'MONOCHROMATIC' as any,
  DICHROMATIC = 'DICHROMATIC' as any
}
