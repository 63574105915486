import {Component, Input, OnInit} from '@angular/core';
import {Auditable} from '@shared/model/auditable';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {

  constructor() { }
  @Input() auditable: Auditable;

  protected readonly console = console;

  ngOnInit(): void {
    console.log(this.auditable);
  }
}
