import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest,  Observable, ReplaySubject} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AppConfigService} from '@core/providers/app-config.service';
import {UserService} from '@core/http/user.service';
import {UserProfile} from '@shared/model/auth/user-profile';
import {AuthConfig, NullValidationHandler, OAuthErrorEvent, OAuthEvent, OAuthService, TokenResponse} from 'angular-oauth2-oidc';
import {CurrentUserService} from '@core/services/current-user.service';
import {JwksValidationHandler} from 'angular-oauth2-oidc-jwks';

function hasType(types: string[]): (event) => boolean {
  return event => types.includes(event.type);
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  // This will publish 'true' if all requests are completed and the user ends up being authenticated.
  public canActivateProtectedRoute$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));

  constructor(private router: Router,
              private oauthService: OAuthService,
              private userService: UserService,
              private currentUserService: CurrentUserService,
              private appConfig: AppConfigService) {
    this.initOAuthConfiguration();
    this.oauthService.setupAutomaticSilentRefresh();

    if (this.appConfig.config().auth.debugInformation) {
      this.subscribeToErrorEvents();
    }

    this.updateIsAuthenticatedStatusOnEvents();
    this.reloadUserProfileOnTokenReceived();
  }

  public initOAuthConfiguration(config?: AuthConfig): void {
    if (config === undefined) {
      config = {
        // The SPA's id. The SPA is registered with this id at the auth-server
        clientId: this.appConfig.config().auth.clientId || '',

        // Url of the Identity Provider
        issuer: this.appConfig.config().auth.issuer || '',

        // URL of the SPA to redirect the user to after login
        redirectUri: this.appConfig.config().auth.redirectUri || '',

        responseType: this.appConfig.config().auth.responseType || 'code',

        // set the scope for the permissions the client should request
        // The first three are defined by OIDC. The 4th is a usecase-specific one
        scope: this.appConfig.config().auth.scope || '',

        // Timout for the silent refresh
        silentRefreshTimeout: this.appConfig.config().auth.silentRefreshTimeout || 200,

        /**
         * Set this to true if you want to use silent refresh together with
         * code flow. As silent refresh is the only option for refreshing
         * with implicit flow, you don't need to explicitly turn it on in
         * this case.
         */
        useSilentRefresh: this.appConfig.config().auth.useSilentRefresh || false,

        // The interceptors waits this time span if there is no token
        waitForTokenInMsec: this.appConfig.config().auth.waitForTokenInMsec || 0,

        // Show debug information or not.
        showDebugInformation: this.appConfig.config().auth.debugInformation || false,

        // Default true, if true every url provided by this document should start with issuer's url.
        strictDiscoveryDocumentValidation: !!this.appConfig.config().auth.strictDiscoveryDocumentValidation,

        requireHttps: 'remoteOnly',
      };
    }

    this.oauthService.configure(config);

    if (config.responseType === 'code') {
      this.oauthService.tokenValidationHandler = new NullValidationHandler();
    } else {
      // todo: When fully adapted to Keycloak we should remove this together with implicit flow.
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    }
  }

  public attemptLogin(targetUrl?: string, params: any = {}) {
    if (this.appConfig.config().auth.hint) {
      params.kc_idp_hint = this.appConfig.config().auth.hint;
    }

    if (this.appConfig.config().auth.audience) {
      console.log('Audience: ', this.appConfig.config().auth.audience);
      params.audience = this.appConfig.config().auth.audience;
    }

    this.oauthService.initLoginFlow(targetUrl || this.router.url, params);
  }

  public initiateSsoSequence(targetUrl?: string, params: any = {}): Promise<void> {
    return this.doFlow(targetUrl, params, true);
  }

  public initiateSequence(): Promise<void> {
    return this.doFlow();
  }

  private doFlow(targetUrl?: string, params: any = {}, singleSignOn: boolean = false): Promise<void> {
    // 1. Load discovery document.
    return this.oauthService.loadDiscoveryDocument()
      .then(() => this.oauthService.tryLogin()) // 2. Hash Login: Try login via hash fragment after redirect back.
      .then(() => {
        if (this.hasValidAccessToken()) {
          return Promise.resolve();
        }

        // 3. Silent Login: Try to refresh the token instead of redirecting the user.
        return this.oauthService.silentRefresh()
          .then(() => Promise.resolve())
          .catch(result => {
            // Trying to refresh the token failed.

            if (singleSignOn) {
              this.attemptLogin(targetUrl, params);

              return Promise.resolve();
            }

            return Promise.reject();
          });
      })
      .then(() => {
        this.isDoneLoadingSubject$.next(true);

        // Before navigating.. We have to make sure that we didn't get any unexpected values.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;

          if (!stateUrl.startsWith('/')) {
            stateUrl = decodeURIComponent(stateUrl);
          }

          this.router.navigateByUrl(stateUrl);
        }
      })
      .catch(() => {
        this.isDoneLoadingSubject$.next(true);
      });
  }

  public loadUserProfile(): Observable<UserProfile> {
    return this.userService.fetchUserProfile().pipe(
      tap(user => this.currentUserService.setUser(user))
    );
  }

  public getUser(): Observable<UserProfile> {
    return this.currentUserService.getUser();
  }

  public logout(): void {
    this.oauthService.logOut();
  }

  public refresh(): Promise<TokenResponse> {
    return this.oauthService.refreshToken();
  }

  public getEvents(): Observable<OAuthEvent> {
    return this.oauthService.events;
  }

  public hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  private updateIsAuthenticatedStatusOnEvents(): void {
    this.oauthService.events
      .subscribe(_ => this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken()));

    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      // console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });
  }

  private reloadUserProfileOnTokenReceived(): void {
    this.oauthService.events
      .pipe(filter(hasType(['token_received', 'discovery_document_loaded'])))
      .subscribe(event => {
        if (this.hasValidAccessToken()) {
          this.loadUserProfile().subscribe(_ => _);
        }
      });
  }

  private subscribeToErrorEvents(): void {
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error('[Auth] OAuthErrorEvent Object:', event);
      } else {
        console.warn('[Auth] OAuthEvent Object:', event);
      }
    });
  }
}
